import {API_TICKET_MESSAGE_URL} from "../config";
import {BaseDataService} from "./BaseDataService";
import axios, {AxiosResponse} from "axios";
import {MediaAsset} from "../interfaces/MediaAsset";
import idToString from "../helpers/entitiesHelper";
import {TicketMessage} from "../interfaces/ticket/TicketMessage";
import TicketMessageCreateRequest from "../interfaces/ticket/TicketMessageCreateRequest";
import {TicketMessageDto} from "../interfaces/ticket/TicketMessageDto";

export type TicketMessageAssets = {
    ticketMessage: TicketMessage;
    assets: MediaAsset[];
};

export default class TicketMessageService extends BaseDataService<TicketMessage> {

    constructor() {
        super(API_TICKET_MESSAGE_URL);
    }

    async registration(request: TicketMessageCreateRequest): Promise<AxiosResponse<TicketMessage>> {
        const formData: FormData = new FormData();
        formData.append("ticketId", request.ticketId.toString());
        formData.append("text", request.text);
        if (request.files) {
            [...request.files].forEach((file, i) => {
                formData.append(`files`, file, file.name);
            });
        }

        return await axios.post<TicketMessage>(
            this.url + "/registration", formData);
    }

    async findByTicketId(ticketId: number) {
        const response: AxiosResponse<TicketMessageDto[]> =
            await axios.get(`${this.url}/by/${ticketId}`);
        return response.data;
    }

    async findAssets(ticketMessageId: number) {
        const response: AxiosResponse<MediaAsset[]> =
            await axios.get(`${this.url}/${ticketMessageId}/assets`);
        return response.data;
    }

    async withMediaAssets(ticketMessages: TicketMessage[]): Promise<TicketMessageAssets[]> {
        const messageAssets: TicketMessageAssets[] = [];
        const ticketMessageAssetsLoader = async (ticketMessage: TicketMessage) => {
            if (ticketMessage.id) {
                const assets = await this.findAssets(ticketMessage.id);
                messageAssets.push({
                    ticketMessage: ticketMessage,
                    assets: assets
                });
            }
        }

        await Promise.all(ticketMessages
            .filter(ticketMessage => ticketMessage.id)
            .map(ticketMessage => ticketMessageAssetsLoader(ticketMessage))
        );

        return messageAssets;
    }

    async notViewedCount() {
        const response: AxiosResponse<any> =
            await axios.get(`${this.url}/not-viewed/count`, {});
        return response;
    }

    async like(ticketMessageId: number) {
        const response: AxiosResponse<any> =
            await axios.post(`${this.url}/${ticketMessageId}/like`, {});
        return response;
    }

    async dislike(ticketMessageId: number) {
        const response: AxiosResponse<any> =
            await axios.post(`${this.url}/${ticketMessageId}/dislike`, {});
        return response;
    }

    async markAsRead(ids: number[]) {
        const response: AxiosResponse<any> =
            await axios.post(`${this.url}/${ids.join(",")}/read`);
        return response;
    }

    async assetBind(ticketMessageId: number, mediaAssetId: number) {
        const response =
            await axios.put(`${this.url}/${ticketMessageId}/assets/${mediaAssetId}/bind`);
        return response.data;
    }

    async assetUnbind(ticketMessageId: number, mediaAssetId: number) {
        const response =
            await axios.delete(`${this.url}/${ticketMessageId}/assets/${mediaAssetId}/unbind`);
        return response.data;
    }

    async assetsUnbind(ticketMessageId: number, mediaAssets: MediaAsset[]) {
        const mediaAssetsId = idToString(mediaAssets);
        const response =
            await axios.delete(`${this.url}/${ticketMessageId}/assets/${mediaAssetsId}/unbind`);
        return response.data;
    }
};
export const ticketMessageService: TicketMessageService = new TicketMessageService();