import React from "react";
import {DateTime} from "luxon";

export default function Footer() {

    const year: number = DateTime.now().year; /*new Date().getUTCFullYear()*/
    const copyright: string = "Независимая Страховая Группа";

    return (
        <div className="flex flex-row flex-wrap align-items-center gap-3">
            <div className="flex-grow-1">
                <div>&copy; {year} {copyright}</div>
                <div>
                    Помощь и поддержка <a
                    href="mailto:support@nsg-ins.ru?subject=Помощь и поддержка ЛК">support@nsg-ins.ru</a>
                </div>
            </div>
            <div>
                <img src="/logo.png" alt={copyright}/>
            </div>
        </div>
    );
}