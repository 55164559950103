import React, {useState} from 'react';
import './App.css';
import MainMenu from "./components/MainMenu";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
// import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css';
// import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
// import 'primereact/resources/themes/fluent-light/theme.css';
// import 'primereact/resources/themes/lara-light-indigo/theme.css';
// import 'primereact/resources/themes/md-light-indigo/theme.css';
// import 'primereact/resources/themes/nano/theme.css';
// import 'primereact/resources/themes/saga-blue/theme.css';
// import 'primereact/resources/themes/saga-green/theme.css';
// import 'primereact/resources/themes/soho-dark/theme.css';
// import 'primereact/resources/themes/tailwind-light/theme.css';
// import 'primereact/resources/themes/saga-blue/theme.css';
// import 'primereact/resources/themes/mdc-light-indigo/theme.css';
// import 'primereact/resources/themes/mdc-dark-indigo/theme.css';
import 'primereact/resources/themes/viva-light/theme.css'; // alpha version
import 'primeflex/primeflex.css';

import Footer from "./layout/Footer";
import Maintenance from "./components/Maintenance";
import {addLocale, locale} from "primereact/api";
import ruLocaleJson from "./primelocale/ru.json";
import AppRoutes from "./components/AppRoutes";
import {useAuth} from "./security/AuthProvider";
import InsuranceProgram from "./interfaces/InsuranceProgram.interface";
import ServiceProgram from "./interfaces/ServiceProgram";
import DispatcherConsole from "./interfaces/DispatcherConsole.interface";
import Organization from "./interfaces/Organization";
import AbstractEntity from "./interfaces/AbstractEntity";
import UserProfile from "./interfaces/UserProfile.interface";
import MediaAssetType from "./interfaces/MediaAssetType";
import {ConfirmDialog} from "primereact/confirmdialog";
import DebugInfo from "./components/DebugInfo";
import {errorMessage} from "./helpers/axiosError";
import {roleService} from "./service/RoleService";
import {userProfileService} from "./service/UserProfileService";
import {dispatcherConsoleService} from "./service/DispatcherConsoleService";
import {insuranceProgramService} from "./service/InsuranceProgramService";
import {mediaAssetTypeService} from "./service/MediaAssetTypeService";
import {organizationService} from "./service/OrganizationService";


/* Установка русской локализации приложения */
addLocale('ru', ruLocaleJson.ru);
locale('ru');


// export let dictRoles: Role[] = [];
export let dictInsurancePrograms: InsuranceProgram[] = [];
export let dictServicePrograms: ServiceProgram[] = [];
export let dictDispatcherConsoles: DispatcherConsole[] = [];
export let dictOrganizations: Organization[] = [];
export let dictTherapists: UserProfile[] = [];
export let dictMediaAssetTypes: MediaAssetType[] = [];

export function findDictValue<T extends AbstractEntity>(entities: T[], id: number | undefined | null) {
    return id ? entities.find(entity => entity.id === id) : undefined;
}

export default function App() {
    const [serverUnavailable, setServerUnavailable] =
        useState<boolean>(false);

    const {isAdmin, isUserManagement} = useAuth();

    if (dictInsurancePrograms.length === 0 && (isAdmin() || isUserManagement())) {
        // console.log("Loading dictionaries");
        Promise.all([
            roleService.findAll(),
            insuranceProgramService.findAll(),
            // serviceProgramService.findAll(),
            dispatcherConsoleService.findAll(),
            organizationService.findAll(),
            userProfileService.findAllTherapists(),
            mediaAssetTypeService.findAll()

        ]).then(response => {
            // console.log("Dictionaries loaded");
            //dictRoles = response[0].content;
            dictInsurancePrograms = response[1].content;
            // dictServicePrograms = response[2].content;
            dictDispatcherConsoles = response[2].content;
            dictOrganizations = response[3].content;
            dictTherapists = response[4].content;
            dictMediaAssetTypes = response[5].content;

        }).catch(reason => {
            console.error(errorMessage(reason));
            setServerUnavailable(true);
        });
    }

    return (
        <div className="App h-full">
            {!serverUnavailable &&
                <>
                    <header className="App-header">
                        <MainMenu/>
                    </header>
                    <section className="content">
                        <AppRoutes/>
                    </section>
                    <footer className="App-footer card text-500 p-5">
                        <Footer/>
                        {(process.env.REACT_APP_API_URL?.includes("alpha") ||
                                process.env.NODE_ENV === "development") &&
                            <DebugInfo/>
                        }
                    </footer>

                    <ConfirmDialog/>
                </>
            }
            {serverUnavailable &&
                <section>
                    <Maintenance/>
                </section>
            }
        </div>
    );
};