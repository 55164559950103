import {Route, Routes} from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import MediaAssetsPage from "../pages/MediaAssetsPage";
import TaxonomyPage from "../pages/admin/taxonomy/TaxonomyPage";
import DispatcherConsolesPage from "../pages/dispatcher-consoles/DispatcherConsolesPage";
import InsuranceProgramsPage from "../pages/insurance-programs/InsuranceProgramPage";
import React from "react";
import Authorization from "../security/Authorization";
import Authentication from "../security/Authentication";
import Logout from "../security/Logout";
import HelpPage from "../pages/HelpPage";
import {ROLE_ADMINISTRATOR, ROLE_THERAPIST, ROLE_USER_MANAGEMENT} from "../config";
import {UsersPage} from "../pages/users/UsersPage";
import SettingsPage from "../pages/admin/settings/SettingsPage";
import ServiceProgramsPage from "../pages/service-programs/ServiceProgramsPage";
import UserDataImportPage from "../pages/UserDataImportPage";
import {MemoizedLogPage} from "../pages/LogPage";
import UserTicketsPage from "../pages/UserTicketsPage";
import TicketMessagePage from "../pages/admin/ticket-messages/TicketMessagePage";
import UserTicketPage from "../pages/UserTicketPage";
import TicketPage from "../pages/admin/tickets/TicketPage";
import OrganizationsPage from "../pages/admin/organizations/OrganizationsPage";
import NotificationListPage from "../pages/admin/notification/list/NotificationListPage";
import NotificationRecipientPage from "../pages/admin/notification/recipient/NotificationRecipientPage";
import MessageTemplatePage from "../pages/admin/notification/template/MessageTemplatePage";
import PasswordPage from "../pages/PasswordPage";
import ProfilePage from "../pages/ProfilePage";

export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={
                <Authentication>
                    <ProfilePage/>
                </Authentication>
            }/>
            <Route path="/profile" element={
                <Authentication>
                    <ProfilePage/>
                </Authentication>
            }/>
            <Route path="/profile/:profileId" element={
                <Authentication>
                    <ProfilePage/>
                </Authentication>
            }/>
            <Route path="/password" element={
                <Authentication>
                    <PasswordPage/>
                </Authentication>
            }/>
            <Route path="/help" element={
                <Authentication>
                    <HelpPage/>
                </Authentication>
            }/>
            <Route path="/tickets" element={
                <Authentication>
                    <UserTicketsPage/>
                </Authentication>
            }/>
            <Route path="/tickets/:ticketId" element={
                <Authentication>
                    <UserTicketPage/>
                </Authentication>
            }/>

            <Route element={<Authorization allowedRoles={[ROLE_ADMINISTRATOR, ROLE_USER_MANAGEMENT]}/>}>
                <Route path="/admin/users" element={<UsersPage/>}/>
                <Route path="/admin/dictionaries/organizations" element={<OrganizationsPage/>}/>
                <Route path="/admin/dictionaries/taxonomy" element={<TaxonomyPage/>}/>
                <Route path="/admin/dictionaries/dispatcher-consoles" element={<DispatcherConsolesPage/>}/>
                <Route path="/admin/documents/insurance/programs" element={<InsuranceProgramsPage/>}/>
                <Route path="/admin/documents/service/programs" element={<ServiceProgramsPage/>}/>
                <Route path="/admin/users/import" element={<UserDataImportPage/>}/>
                <Route path="/admin/assets" element={<MediaAssetsPage/>}/>
            </Route>

            <Route element={<Authorization allowedRoles={[ROLE_ADMINISTRATOR, ROLE_THERAPIST]}/>}>
                <Route path="/admin/tickets" element={<TicketPage/>}/>
                <Route path="/admin/ticket-messages" element={<TicketMessagePage/>}/>
            </Route>

            <Route element={<Authorization allowedRoles={[ROLE_ADMINISTRATOR]}/>}>
                <Route path="/admin/taxonomy" element={<TaxonomyPage/>}/>
                <Route path="/admin/notification/recipient" element={<NotificationRecipientPage/>}/>
                <Route path="/admin/notification/list" element={<NotificationListPage/>}/>
                <Route path="/admin/notification/messageTemplates" element={<MessageTemplatePage/>}/>
                <Route path="/admin/settings" element={<SettingsPage/>}/>
                <Route path="/admin/log" element={<MemoizedLogPage/>}/>
            </Route>

            <Route path="/login" element={<LoginPage/>}/>
            <Route path="/logout" element={<Logout/>}/>
        </Routes>
    );
};