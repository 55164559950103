import {DataTable, DataTableFilterEvent, DataTablePageEvent, DataTableSortEvent} from "primereact/datatable";
import React, {useState} from "react";
import {Column} from "primereact/column";
import {useMountEffect, useStorage, useUpdateEffect} from "primereact/hooks";
import TableState from "../../interfaces/TableState";
import tableStateToSorting from "../../components/common/dataTable/tableStateToSorting";
import tableStateToPage from "../../components/common/dataTable/tableStateToPage";
import useUserNotification from "../../hooks/useUserNotification";
import UserPropertiesDialog, {UserDialogState} from "./UserPropertiesDialog";
import ApiResponse from "../../interfaces/response.interface";
import User, {initialUser} from "../../interfaces/User.interface";
import {pfsFilterToSpring} from "../../helpers/primereact-turkraft";
import {initialUserColumns, usersPageColumns} from "./UsersPageColumns";
import EditButton from "../../components/common/buttons/EditButton";
import {errorMessage} from "../../helpers/axiosError";
import ColumnToggle, {visibleOnly} from "../../components/common/dataTable/ColumnToggle";
import ReloadButton from "../../components/common/buttons/ReloadButton";
import FilterResetButton from "../../components/common/buttons/FilterResetButton";
import CreateButton from "../../components/common/buttons/CreateButton";
import {usersPaginationInitialState, usersRowsPerPage, usersSortingInitialState} from "./UsersPageConstants";
import {usersFilterInitialState} from "./UsersPageFilter";
import {confirmDialog} from "primereact/confirmdialog";
import DeleteButton from "../../components/common/buttons/DeleteButton";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {userService} from "../../service/UserService";


export function UsersPage() {

    const [busy, setBusy] =
        useState<boolean>(false);

    const [tableState, setTableState] =
        useState<TableState>({
            filters: usersFilterInitialState,
            pagination: usersPaginationInitialState,
            sorting: usersSortingInitialState
        });

    const [dialogState, setDialogState] =
        useState<UserDialogState>({
            mode: "edit",
            entity: {...initialUser},
            visible: false
        });

    const [users, setUsers] =
        useState<User[]>([]);

    const [totalRecords, setTotalRecords] =
        useState<number>(0);

    const [selected, setSelected] =
        useState<User[]>([]);

    const [visibleFields, setVisibleFields] =
        useStorage<string[]>(initialUserColumns(), "nsg-pa-dt-users-cols");

    const [globalFilterValue, setGlobalFilterValue] =
        useState<string>('');

    const {showError, showSuccess} = useUserNotification();

    useMountEffect(() => {
        reload();
    });

    useUpdateEffect(() => {
        reload();
    }, [tableState]);

    const initFilters = () => {
        setTableState(prevState => ({
            ...prevState,
            ...{filters: usersFilterInitialState}
        }));
        setGlobalFilterValue('');
    };

    const hasSelected = () => {
        return selected && selected.length > 0;
    };

    const reload = () => {
        setBusy(true);

        const paging = tableStateToPage(tableState);
        const sorting = tableStateToSorting(tableState);
        const filter = pfsFilterToSpring(tableState.filters);

        userService.findAll(filter, paging, sorting)
            .then((response: ApiResponse<User>) => {
                setUsers(response.content);
                setTotalRecords(response.totalElements);

            })
            .catch(reason => {
                setUsers([]);
                showError({
                    summary: "Ошибка загрузки пользователей",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => {
                setBusy(false);
                setSelected([])
                /*setVisibleFields(userPropertiesColumns
                    .filter(column => !column.hidden)
                    .map(column => column.field)
                )*/
            });
    };

    const deleteSelected = () => {
        if (hasSelected()) {
            setBusy(true);
            userService.deleteAll(selected)
                .then(() => {
                    showSuccess({
                        summary: "Удаление пользователей",
                        detail: "Выбранные пользователи успешно удалены."
                    });
                    reload();
                })
                .catch(reason => showError({
                    summary: "Удаление пользователей",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));
        }
    }

    const deleteConfirmation = () => {
        confirmDialog({
            message: "Вы действительно хотите удалить выбранных пользователей? Операция удаления необратима.",
            header: "Удаление пользователей",
            icon: "pi pi-info-circle",
            acceptClassName: 'p-button-danger',
            acceptLabel: "Да",
            rejectLabel: "Нет",
            className: "app-confirmation-dialog",
            accept() {
                deleteSelected();
            }
        });
    };

    const editButtonTemplate = (user: User) => {
        return <EditButton onClick={() => {
            setDialogState({
                mode: "edit",
                entity: {...user},
                visible: true
            });
        }} tooltip="Редактировать карточку пользователя"/>
    };

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setGlobalFilterValue(value);
    };

    const onSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!globalFilterValue || globalFilterValue.length === 0) reload();

        userService.search(globalFilterValue).then(result => {
            setUsers(result);
            setTotalRecords(result ? result.length : 0);
        }).catch(reason => showError({
            summary: "Ошибка поиска пользователей",
            detail: errorMessage(reason)
        }));
    }

    /* Шапка и подвал таблицы */

    const tableHeader = (
        <div className="flex flex-row gap-2 justify-content-between">
            <div className="flex flex-row gap-1">
                <ReloadButton onClick={reload} disabled={busy}/>
                <FilterResetButton onClick={initFilters} disabled={busy}/>
                <CreateButton tooltip="Зарегистрировать нового пользователя"
                              onClick={() => {
                                  const newState: UserDialogState = {
                                      mode: "create",
                                      entity: {...initialUser},
                                      visible: true
                                  };
                                  setDialogState(newState);
                              }}
                              disabled={busy}
                              className="ml-1"/>
                <DeleteButton onClick={deleteConfirmation}
                              tooltip="Удалить выбранных пользователей"
                              disabled={!hasSelected() || busy}
                />
            </div>
            {/*<GlobalQuickSearch value={globalFilterValue}
                               onChange={onGlobalFilterChange}/>*/}

            <form onSubmit={onSearchSubmit} className="p-inputgroup flex-1">
                {/*<span className="p-inputgroup-addon">
                    <i className="pi pi-search"></i>
                </span>*/}
                <InputText value={globalFilterValue}
                           placeholder="Имя, фамилия, отчество, телефон, email, номер страхового полиса"
                           onChange={onGlobalFilterChange}/>
                <Button type="submit"
                        icon="pi pi-search"
                        tooltip="Выполнить поиск"/>
            </form>

            <ColumnToggle columns={usersPageColumns}
                          visibleFields={visibleFields}
                          onColumnToggle={fields => setVisibleFields(fields)}/>
        </div>
    );

    const tableFooter = `Всего ${totalRecords} пользователей`;


    /*
     * Обработчики событий
     */

    const onPage = (event: DataTablePageEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{pagination: event}
        }));
    };

    const onSort = (event: DataTableSortEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{sorting: event}
        }));
    };

    const onFilter = (event: DataTableFilterEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{filters: event.filters}
        }));
    };

    return (
        <div className="container-fluid">
            <DataTable value={users} dataKey="id"
                       header={tableHeader} footer={tableFooter}
                       lazy loading={busy} first={tableState.pagination.first} totalRecords={totalRecords}
                       filterDisplay="menu" onFilter={onFilter} filters={tableState.filters} filterLocale="ru"
                // globalFilter={globalFilterValue}
                // globalFilterFields={['profile.secondName', 'profile.firstName', 'profile.thirdName', 'mobilePhone.number', 'email', 'profile.insurancePolicyNumber']}
                       sortField={tableState.sorting.sortField} sortOrder={tableState.sorting.sortOrder} onSort={onSort}
                       paginator rows={tableState.pagination.rows}
                       rowsPerPageOptions={usersRowsPerPage}
                       onPage={onPage}
                       selectionMode="multiple" selection={selected!}
                       onSelectionChange={e => setSelected(e.value)}
                       scrollable={true} size="small"
                       stateStorage="local" stateKey="nsg-pa-dt-users"
                       emptyMessage="Нет данных">

                <Column selectionMode="multiple"/>
                <Column body={editButtonTemplate}/>

                {visibleOnly(visibleFields, usersPageColumns)}

            </DataTable>

            {/* Диалог регистрации нового или редактирования свойств существующего пользователя */}

            <UserPropertiesDialog user={dialogState.entity}
                                  mode={dialogState.mode}
                                  visible={dialogState.visible}
                                  onHide={() => setDialogState(prev => ({
                                      ...prev,
                                      ...{visible: false}
                                  }))}
                                  onCancel={() => setDialogState(prev => ({
                                      ...prev,
                                      ...{visible: false}
                                  }))}
                                  onSave={() => {
                                      setDialogState(prev => ({
                                          ...prev,
                                          ...{visible: false}
                                      }));
                                      reload();
                                  }}
            />
        </div>
    );
}

export const MemoizedUsersPage = React.memo(UsersPage);