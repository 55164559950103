import {BaseDataService} from "./BaseDataService";
import AbstractEntity from "../interfaces/AbstractEntity";
import TaxonomyHolder from "../interfaces/TaxonomyHolder";
import Taxonomy from "../interfaces/Taxonomy";
import axios, {AxiosResponse} from "axios";


export class BaseTaxonomyHolder<T extends AbstractEntity>
    extends BaseDataService<T>
    implements TaxonomyHolder {

    /* Taxonomy */

    async taxonomy(objectId: number): Promise<Taxonomy[]> {
        const response: AxiosResponse<Taxonomy[]> =
            await axios.get(`${this.url}/${objectId}/taxonomy`);
        return response.data;
    }

    async taxonomyUpdate(objectId: number, taxonomyIds: number[]): Promise<any> {
        const response: AxiosResponse<Taxonomy[]> =
            await axios.put(`${this.url}/${objectId}/taxonomy/${taxonomyIds}`);
        return response.data;
    }

    async taxonomyBind(objectId: number, taxonomyId: number): Promise<any> {
        const response =
            await axios.put(`${this.url}/${objectId}/taxonomy/${taxonomyId}/bind`);
        return response.data;
    }

    async taxonomyUnbind(objectId: number, taxonomyIds: number[]): Promise<any> {
        const response =
            await axios.delete(`${this.url}/${objectId}/taxonomy/${taxonomyIds}/unbind`);
        return response.data;
    }
}