export const executableExtensions: string[] = [
    "action",
    "apk",
    "app",
    "ade", "adp", "and",
    "bat",
    "bin",
    "cmd",
    "com",
    "command",
    "cpl",
    "csh",
    "dll",
    "exe",
    "gadget",
    "hta",
    "inf1",
    "ins",
    "inx",
    "ipa",
    "isu",
    "jar",
    "job",
    "js",
    "jse",
    "ksh",
    "lnk",
    "msc",
    "msi",
    "msp",
    "mst",
    "osx",
    "out",
    "paf",
    "pif",
    "ps1",
    "reg",
    "rgs",
    "run",
    "scr",
    "sct",
    "sh",
    "shb",
    "shs",
    "u3p",
    "vb",
    "vbe",
    "vbs",
    "vbscript",
    "workflow",
    "ws",
    "wsc",
    "wsf",
    "wsh",
];