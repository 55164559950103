import {BaseDataService} from "../BaseDataService";
import {API_NOTIFICATION_RECIPIENTS_URL} from "../../config";
import NotificationRecipientSpecial from "../../interfaces/notification/NotificationRecipientSpecial";

export default class NotificationRecipientSpecialService extends BaseDataService<NotificationRecipientSpecial> {

    constructor() {
        super(API_NOTIFICATION_RECIPIENTS_URL + "/special");
    }
}
export const notificationRecipientSpecialService: NotificationRecipientSpecialService = new NotificationRecipientSpecialService();