import {API_PROFILES_URL, ROLE_THERAPIST} from "../config";
import {BaseDataService} from "./BaseDataService";
import UserProfile from "../interfaces/UserProfile.interface";
import ApiResponse from "../interfaces/response.interface";

export default class UserProfileService extends BaseDataService<UserProfile> {


    constructor() {
        super(API_PROFILES_URL);
    }

    async findAllTherapists(): Promise<ApiResponse<UserProfile>> {
        return this.findAll("filter=user.roles.id:" + ROLE_THERAPIST.code);
    }
}
export const userProfileService: UserProfileService = new UserProfileService();