import ColumnMeta from "../../../interfaces/ColumnMeta";
import {Column} from "primereact/column";
import {dateTimeRangeFilterTemplate, numberFilterTemplate, textFilterTemplate} from "../dataTable/filterTemplates";
import {ldtf} from "../../../helpers/dateTime";
import React from "react";
import MediaAssetTypeFilter from "../dataTable/filter/MediaAssetTypeFilter";
import AbstractEntity from "../../../interfaces/AbstractEntity";
import TemporalUpdatableEntity from "../../../interfaces/TemporalUpdatableEntity";
import {MediaAsset} from "../../../interfaces/MediaAsset";
import MediaAssetTypeEnumOutput from "./MediaAssetTypeEnumOutput";

export function initialMediaAssetColumns() {
    return mediaAssetPanelColumns
        .filter(column => !column.hidden)
        .map(column => column.field)
}

export const mediaAssetPanelColumns: ColumnMeta[] = [
    {
        field: 'id',
        header: 'УН',
        hidden: true,
        column: <Column key="id" field="id" header="УН" sortable dataType="numeric"
                        body={(entity: AbstractEntity) => <>{entity.id}</>}
                        filter filterElement={numberFilterTemplate}
        />
    },
    {
        field: 'created',
        header: 'Создан',
        hidden: true,
        column: <Column key="created" field="created" header="Создан" sortable
                        dataType="date" body={(temporal: TemporalUpdatableEntity) => ldtf(temporal.created)}
                        filter filterElement={dateTimeRangeFilterTemplate}
                        showFilterMenu={true}
                        showFilterMatchModes={false}
                        showFilterOperator={false}
                        showFilterMenuOptions={false}
        />
    },
    {
        field: 'updated',
        header: 'Обновлен',
        hidden: true,
        column: <Column key="updated" field="updated" header="Обновлен" sortable
                        dataType="date" body={(temporal: TemporalUpdatableEntity) => ldtf(temporal.updated)}
                        filter filterElement={dateTimeRangeFilterTemplate}
                        showFilterMenu={true}
                        showFilterMatchModes={false}
                        showFilterOperator={false}
                        showFilterMenuOptions={false}
        />
    },
    {
        field: "uuid",
        header: "UUID",
        hidden: true,
        column: <Column key="uuid" field="uuid" header="UUID" dataType="text"
                        sortable
                        filter filterElement={textFilterTemplate}
        />
    },
    {
        field: "title",
        header: "Название",
        hidden: false,
        column: <Column key="uuid" field="title" header="Название" dataType="text"
                        sortable
                        filter filterElement={textFilterTemplate}
        />
    },
    {
        field: "name",
        header: "Имя",
        hidden: false,
        column: <Column key="name" field="name" header="Имя" dataType="text"
                        sortable
                        filter filterElement={textFilterTemplate}
        />
    },
    {
        field: "type",
        header: "Тип",
        hidden: false,
        column: <Column key="type" field="type" header="Тип"
                        body={(asset: MediaAsset) => <MediaAssetTypeEnumOutput value={asset.type}/>}
                        sortable
                        filter filterElement={MediaAssetTypeFilter}
        />
    },
    {
        field: 'contentMimeType',
        header: 'Mime-тип',
        hidden: false,
        column: <Column key="contentMimeType" field="contentMimeType" header="Mime-тип" sortable
                        dataType="text" body={(asset: MediaAsset) => <>{asset.contentMimeType}</>}
                        filter filterElement={textFilterTemplate}
        />
    },
    {
        field: "contentLength",
        header: "Размер",
        hidden: false,
        column: <Column key="contentLength"
                        field="contentLength"
                        header="Размер, байт"
                        dataType="numeric"
                        sortable
                        filter
        />
    },
];