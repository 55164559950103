import {Menubar} from "primereact/menubar";
import React, {useState} from "react";
import {useAuth} from "../security/AuthProvider";
import {Button} from "primereact/button";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {useMountEffect, useUpdateEffect} from "primereact/hooks";
import {MenuItem} from "primereact/menuitem";
import MainMenuAvatar from "./MainMenuAvatar";
import useUserNotification from "../hooks/useUserNotification";
import {useAppSettings} from "../providers/AppSettingsProvider";
import Alpha from "./common/Alpha";
import {Badge} from "primereact/badge";
import {errorMessage} from "../helpers/axiosError";
import {userDataExchangeService} from "../service/exchange/excel/UserDataExportService";
import {ticketMessageService} from "../service/TicketMessageService";

export default function MainMenu() {
    const navigate: NavigateFunction = useNavigate();
    const {showError, showInfo} = useUserNotification();
    const {isRegistered, isAdmin, isUserManagement, isTherapist, userProfile} = useAuth();
    const {appSettings} = useAppSettings();

    const [items, setItems] = useState<MenuItem[]>([]);

    const adminMenuItems: MenuItem[] = [];
    const adminMenuRoot: MenuItem = {
        label: 'Администрирование',
        icon: 'pi pi-fw pi-wrench',
        items: adminMenuItems
    };

    const [notViewed, setNotViewed] = useState<number | undefined>(undefined);

    /**
     * Визуализирует пункт меню Обращения с указанием количества не просмотренных Обращений.
     * @param item пункт меню.
     */
    const itemRenderer = (item: MenuItem) => (
        <a href={item.url} className="flex align-items-center p-menuitem-link">
            <span className={item.icon}/>
            <span className="mx-2">{item.label}</span>
            {notViewed !== undefined && notViewed > 0 && <Badge className="ml-auto" value={notViewed}/>}
        </a>
    );

    /**
     * Загружает количество не просмотренных пользователем Обращений.
     */
    const reloadNotViewedCount = () => {
        ticketMessageService.notViewedCount()
            .then(response => {
                setNotViewed(response.data);
            })
            .catch(reason => {
                setNotViewed(undefined);
                showError({
                    summary: "Ошибка загрузки количества непросмотренных сообщений",
                    detail: errorMessage(reason)
                });
            });
    }

    const reload = () => {
        const mi: MenuItem[] = [];
        if (isRegistered()) {
            mi.push({
                label: 'Главная',
                icon: 'pi pi-fw pi-home',
                command: () => navigate('/')
            });
        }
        if (isRegistered()) {
            reloadNotViewedCount();

            if (appSettings && appSettings.ticketServiceEnabled &&
                (isAdmin() || isTherapist() || userProfile.therapist)) {
                mi.push({
                    label: `Обращения`,
                    icon: 'pi pi-fw pi-comment',
                    command: () => navigate('/tickets'),
                    template: itemRenderer
                });
            }
            mi.push({
                label: 'Помощь',
                icon: 'pi pi-fw pi-question',
                command: () => navigate('/help')
            });
        }
        if (isAdmin() || isUserManagement()) {
            adminMenuItems.push(
                {
                    label: 'Пользователи',
                    icon: 'pi pi-fw pi-user',
                    command: () => navigate('/admin/users'),
                    items: [
                        {
                            label: 'Импорт',
                            icon: 'pi pi-fw pi-file-excel',      // pi-file-import
                            command: () => navigate('/admin/users/import')
                        },
                        {
                            label: 'Экспорт',
                            icon: 'pi pi-fw pi-file-excel',     // pi-file-export
                            command: () => {
                                userDataExchangeService.excelTemplate(
                                    () => {
                                        showInfo({
                                            summary: "Файл шаблона",
                                            detail: "Файл шаблона готов для скачивания."
                                        });
                                    },
                                    error => {
                                        console.error(error);
                                        showError({
                                            summary: "Файл шаблона",
                                            detail: error.message
                                        });
                                    }
                                );
                            }
                        }
                    ]
                },
                {
                    label: 'Организации',
                    icon: 'pi pi-fw pi-building',
                    command: () => navigate('admin/dictionaries/organizations')
                },
                {
                    label: 'Программы страхования',
                    icon: 'pi pi-fw pi-briefcase',
                    command: () => navigate('/admin/documents/insurance/programs')
                },
                /*{
                    label: 'Планы обслуживания',
                    icon: 'pi pi-fw pi-briefcase',
                    command: () => navigate('/admin/documents/service/programs')
                },*/
                {
                    label: 'Диспетчерские пульты',
                    icon: 'pi pi-fw pi-table',
                    command: () => navigate('/admin/dictionaries/dispatcher-consoles')
                },
            );
        }
        if (isAdmin() || isTherapist()) {
            adminMenuItems.push(
                {
                    label: 'Обращения',
                    icon: 'pi pi-fw pi-twitch',
                    items: [
                        {
                            label: 'Темы',
                            icon: 'pi pi-fw pi-comments',
                            command: () => navigate('/admin/tickets'),
                        },
                        {
                            label: 'Сообщения',
                            icon: 'pi pi-fw pi-comments',
                            command: () => navigate('/admin/ticket-messages')
                        }
                    ]
                }
            );
        }
        if (isAdmin() || isUserManagement()) {
            adminMenuItems.push(
                {
                    label: 'Файлы',
                    icon: 'pi pi-fw pi-file',
                    command: () => navigate('/admin/assets')
                }
            );
        }
        if (isAdmin()) {
            adminMenuItems.push(
                {
                    separator: true
                },
                {
                    label: 'Таксономия',
                    icon: 'pi pi-fw pi-sitemap',
                    command: () => navigate('admin/taxonomy')
                },
                {
                    label: 'Рассылки',
                    icon: 'pi pi-fw pi-megaphone',
                    items: [
                        {
                            label: 'Адресаты',
                            icon: 'pi pi-fw pi-user',
                            command: () => navigate('admin/notification/recipient')
                        },
                        {
                            label: 'Списки',
                            icon: 'pi pi-fw pi-list',
                            command: () => navigate('admin/notification/list')
                        },
                        {
                            label: 'Шаблоны сообщений',
                            icon: 'pi pi-fw pi-code',
                            command: () => navigate('admin/notification/messageTemplates')
                        },
                    ]
                },
                {
                    label: 'Журнал',
                    icon: 'pi pi-fw pi-history',
                    command: () => navigate("/admin/log")
                },
                {
                    label: 'Настройки',
                    icon: 'pi pi-fw pi-wrench',
                    command: () => navigate('/admin/settings')
                },
            )
        }
        if (isAdmin() || isTherapist() || isUserManagement()) {
            mi.push(adminMenuRoot);
        }

        setItems(mi);
    }

    useMountEffect(() => reload());
    useUpdateEffect(() => reload(), [appSettings, userProfile]);


    const menuStart = <div className="flex flex-row align-items-center gap-3 mr-3">
        <Button onClick={() => navigate("/")} link>
            <img id="logo" src="/logo.png" alt=""/>
        </Button>
        <Alpha/>
    </div>;

    const menuEnd = <div className="flex flex-row align-items-center">
        {isRegistered() && <MainMenuAvatar/>}
    </div>


    return (
        <Menubar model={items}
                 start={menuStart}
                 end={menuEnd}
        />
    );
};