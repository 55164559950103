import {BaseDataService} from "./BaseDataService";
import {MediaAsset} from "../interfaces/MediaAsset";
import {API_MEDIA_ASSETS_URL} from "../config";
import axios, {AxiosError, AxiosResponse} from "axios";
import {ApiErrorResponse} from "../interfaces/response.interface";

export default class MediaAssetService extends BaseDataService<MediaAsset> {

    constructor() {
        super(API_MEDIA_ASSETS_URL);
    }

    filename(asset: MediaAsset) {
        if (asset.title)
            return  asset.title + "." + asset.extension;
        else if (asset.name)
            return  asset.name;
        else
            return  "File_" + Date.now() + "." + asset.extension;
    }

    resource(asset: MediaAsset) {
        return API_MEDIA_ASSETS_URL + "/resource/uuid/" + asset.uuid;
    }

    async downloadAsset(asset: MediaAsset){
        await this.download(asset.id, this.filename(asset));
    }

    async download(id: number | undefined | null, filename: string,
                   onSuccess?: () => void,
                   onError?: (error: AxiosError) => void) {

        if (!id) return;

        const request = API_MEDIA_ASSETS_URL + "/download/" + id;
        axios.get(request, {
            //headers: { 'Content-Type': contentType },
            responseType: 'blob'

        })
            .then((response: AxiosResponse<Blob>) => {
                // Теоретически рабочий метод получения имени файла из заголовка content-disposition.
                // На сервере есть установка этого заголовка, но при обработке возникает ошибка.
                // Нужно отлаживать.
                /*const disposition = response.headers['content-disposition'];
                filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
                if (filename.toLowerCase().startsWith("utf-8''"))
                    filename = decodeURIComponent(filename.replace("utf-8''", ''));
                else
                    filename = filename.replace(/['"]/g, '');*/

                return response.data;

            })
            .then((blob: Blob) => {
                const href: string = window.URL.createObjectURL(blob);
                const a: HTMLAnchorElement = document.createElement('a');
                a.href = href;
                a.download = filename;
                document.body.appendChild(a);       // append the element to the dom
                a.click();
                a.remove();                         // afterwards, remove the element

                if (onSuccess) {
                    onSuccess();
                }

            })
            .catch((error: AxiosError<ApiErrorResponse>) => {
                if (onError) {
                    onError(error)
                }
            });
    }
}
export const mediaAssetService: MediaAssetService = new MediaAssetService();