import {useParams} from "react-router-dom";
import UserTicketMessage from "./UserTicketMessage";
import React, {useRef, useState} from "react";
import useUserNotification from "../hooks/useUserNotification";
import {BlockUI} from "primereact/blockui";
import {useMountEffect} from "primereact/hooks";
import UserTicketMessageForm from "./UserTicketMessageForm";
import {Ticket} from "../interfaces/ticket/Ticket";
import {errorMessage} from "../helpers/axiosError";
import {TicketMessageDto} from "../interfaces/ticket/TicketMessageDto";
import {useAuth} from "../security/AuthProvider";
import {Toolbar} from "primereact/toolbar";
import {Button} from "primereact/button";
import {TicketStatus} from "../interfaces/enum/TicketStatus";
import {confirmDialog} from "primereact/confirmdialog";
import {getClassifierItemTitle} from "../components/common/StatusOutput";
import {ticketStatusTitles} from "../interfaces/dictionaries/TicketStatusTitles";
import {TicketMessageStatus} from "../interfaces/enum/TicketMessageStatus";
import {ticketService} from "../service/TicketService";
import {ticketMessageService} from "../service/TicketMessageService";
import {CSSTransition} from "primereact/csstransition";

export default function UserTicketPage() {
    const {ticketId} = useParams();
    const {isAdmin, isTherapist} = useAuth();
    const {showError, showSuccess} = useUserNotification();

    const [busy, setBusy] =
        useState<boolean>(false);

    const [ticket, setTicket] =
        useState<Ticket | undefined>(undefined);

    const [messages, setMessages] =
        useState<TicketMessageDto[]>([]);

    const [showMessageForm, setShowMessageForm] =
        useState<boolean>(false);

    const formContainerRef = useRef(null);


    const reload = () => {
        if (!ticketId) return;
        let ticketIdn = Number(ticketId);

        setShowMessageForm(false);
        setBusy(true);

        ticketService.find(ticketIdn)
            .then(response => {
                setTicket(response);
            })
            .catch(reason => {
                showError({
                    summary: "Загрузка обращения",
                    detail: errorMessage(reason)
                });
            })
            .finally()

        ticketMessageService.findByTicketId(ticketIdn)
            .then(response => {
                setMessages(response);
                setTimeout(() => markAsViewed(response), 2000);

            })
            .catch(reason => console.error(errorMessage(reason)))
            .finally(() => setBusy(false));
    }


    const markAsViewed = (messages: TicketMessageDto[]) => {
        if (messages) {
            const messageIds: number[] = messages
                .filter(m => !m.viewed)
                .map(m => m.id);

            if (messageIds && messageIds.length > 0) {
                ticketMessageService
                    .markAsRead(messageIds)
                    .then(() => {
                        const now = Date();
                        setMessages([...messages].map(m => {
                            m.viewed = now;
                            return m;
                        }));
                    })
                    .catch(reason => console.error(errorMessage(reason)));
            }
        }
    };

    useMountEffect(() => {
        reload();
    });

    const renderMessages = () => {
        if (ticket) {
            if (isAdmin() || isTherapist())
                return messages
                    .map((message, index) =>
                        <UserTicketMessage key={index}
                                           ticket={ticket}
                                           message={message}/>)
            else
                return messages
                    .filter(m => m.status === TicketMessageStatus.VISIBLE)
                    .map((message, index) =>
                        <UserTicketMessage key={index}
                                           ticket={ticket}
                                           message={message}/>)
        }
    }

    const updateStatusConfirmation = (status: TicketStatus) => {
        const statusTitle = getClassifierItemTitle(status, ticketStatusTitles);
        confirmDialog({
            message: `Вы действительно хотите изменить статус обращения на '${statusTitle}'?`,
            header: "Изменение статуса обращения",
            icon: "pi pi-info-circle",
            acceptClassName: 'p-button-danger',
            acceptLabel: "Да",
            rejectLabel: "Нет",
            className: "app-confirmation-dialog",
            accept() {
                updateStatus(status);
            }
        });
    };

    const updateStatus = (status: TicketStatus) => {
        if (!ticket) throw new Error("Обращение не задано.");
        const statusTitle = getClassifierItemTitle(status, ticketStatusTitles);
        ticketService.updateStatus(ticket, status)
            .then(() => showSuccess({
                summary: "Изменение статуса обращения",
                detail: `Статус обращения успешно изменён на '${statusTitle}'.`
            }))
            .catch(reason => showError({
                summary: "Изменение статуса обращения",
                detail: errorMessage(reason)
            }));
    };

    const startContent = (
        <div className="flex flex-row flex-wrap gap-3">
            <div className="flex flex-row flex-wrap gap-1">
                <Button icon="pi pi-cog"
                        label="В работу"
                        onClick={() => updateStatusConfirmation(TicketStatus.PROGRESS)}
                        outlined
                        disabled={ticket?.status === TicketStatus.PROGRESS}
                />
                <Button icon="pi pi-check"
                        label="Завершить"
                        onClick={() => updateStatusConfirmation(TicketStatus.COMPLETED)}
                        outlined
                        disabled={ticket?.status === TicketStatus.COMPLETED}
                />
            </div>
            <div className="flex flex-row flex-wrap gap-1">
                <Button icon="pi pi-lock"
                        label="Заблокировать"
                        onClick={() => updateStatusConfirmation(TicketStatus.BLOCKED)}
                        outlined
                        severity="help"
                        disabled={ticket?.status === TicketStatus.BLOCKED}
                />
                <Button icon="pi pi-eye"
                        label="Скрыть"
                        onClick={() => updateStatusConfirmation(TicketStatus.HIDDEN)}
                        outlined
                        severity="help"
                        disabled={ticket?.status === TicketStatus.HIDDEN}
                />
                {(isAdmin()) &&
                    <Button icon="pi pi-trash"
                            label="Удалить"
                            onClick={() => updateStatusConfirmation(TicketStatus.DELETED)}
                            outlined
                            severity="danger"
                            disabled={ticket?.status === TicketStatus.DELETED}
                    />
                }
            </div>
        </div>
    );

    const allowed = () => {
        /*if (ticket && (isAdmin() || isTherapist())) return true;
        if (ticket && isRegistered())
            return !(
                ticket.status === TicketStatus.HIDDEN ||
                ticket.status === TicketStatus.DELETED
            );*/
        return true;
    }

    if (ticket && allowed()) {
        return (
            <div className="container">
                <BlockUI blocked={busy}>
                    {(isAdmin() || isTherapist()) &&
                        <Toolbar start={startContent}
                                 className="mb-3"/>
                    }
                    {(isAdmin() || isTherapist() || ticket.status !== TicketStatus.BLOCKED) &&
                        <>
                            <Button label="Отправить сообщение"
                                    type="button"
                                    onClick={() => setShowMessageForm(!showMessageForm)}
                                    className="mt-2"/>

                            <CSSTransition timeout={300}
                                           nodeRef={formContainerRef}
                                           in={showMessageForm}
                                           classNames="opacity-transition"
                                           unmountOnExit
                                           appear>
                                <article ref={formContainerRef}
                                         className="mt-2">
                                    <UserTicketMessageForm ticket={ticket}
                                                           doSubmit={() => reload()}/>
                                </article>
                            </CSSTransition>
                        </>
                    }
                    <article>
                        {renderMessages()}
                    </article>

                </BlockUI>
            </div>
        );
    } else {
        return <div className="container">
            Обращение не найдено.
        </div>
    }
}