import {Card} from "primereact/card";
import {DataTable, DataTableFilterEvent, DataTablePageEvent, DataTableSortEvent} from "primereact/datatable";
import {Column} from "primereact/column";
import React, {useRef, useState} from "react";
import {confirmDialog} from "primereact/confirmdialog";
import {useMountEffect, useStorage, useUpdateEffect} from "primereact/hooks";
import ReloadButton from "../../components/common/buttons/ReloadButton";
import {errorMessage} from "../../helpers/axiosError";
import useUserNotification from "../../hooks/useUserNotification";
import FilterResetButton from "../../components/common/buttons/FilterResetButton";
import ColumnToggle, {visibleOnly} from "../../components/common/dataTable/ColumnToggle";
import CreateButton from "../../components/common/buttons/CreateButton";
import DeleteButton from "../../components/common/buttons/DeleteButton";
import EditButton from "../../components/common/buttons/EditButton";
import TableState from "../../interfaces/TableState";
import {initialServiceProgramColumns, serviceProgramColumns} from "./ServiceProgramColumns";
import {serviceProgramFilterInitialState} from "./ServiceProrgramFilter";
import {
    serviceProgramPaginationInitialState,
    serviceProgramRowsPerPage,
    serviceProgramSortingInitialState
} from "./ServiceProgramConstants";
import tableStateToPage from "../../components/common/dataTable/tableStateToPage";
import tableStateToSorting from "../../components/common/dataTable/tableStateToSorting";
import {pfsFilterToSpring} from "../../helpers/primereact-turkraft";
import {assetName} from "../../helpers/assets";
import {Tag} from "primereact/tag";
import {OverlayPanel} from "primereact/overlaypanel";
import ServiceProgramAssets from "./ServiceProgramAssets";
import {Button} from "primereact/button";
import ServiceProgramPropertiesDialog, {ServiceProgramDialogState} from "./ServiceProgramPropertiesDialog";
import ServiceProgram, {initialServiceProgram} from "../../interfaces/ServiceProgram";
import {ServiceProgramsAssets, serviceProgramService} from "../../service/ServiceProgramService";
import {useAuth} from "../../security/AuthProvider";

export default function ServiceProgramsPage() {

    const [busy, setBusy] = useState(false);

    const [tableState, setTableState] =
        useState<TableState>({
            filters: serviceProgramFilterInitialState,
            pagination: serviceProgramPaginationInitialState,
            sorting: serviceProgramSortingInitialState
        });

    const [dialogState, setDialogState] =
        useState<ServiceProgramDialogState>({
            mode: "create",
            entity: {...initialServiceProgram},
            visible: false
        });


    const [servicePrograms, setServicePrograms] =
        useState<ServiceProgram[]>([]);

    const [serviceProgramsAssets, setServiceProgramsAssets] =
        useState<ServiceProgramsAssets[] | undefined>(undefined);

    const [totalRecords, setTotalRecords] =
        useState<number>(0);

    const [selected, setSelected] =
        useState<ServiceProgram[]>([]);

    const [visibleFields, setVisibleFields] =
        useStorage<string[]>(initialServiceProgramColumns(), "nsg-pa-dt-service-program-cols");

    const [serviceProgramAsset, setServiceProgramAssetEditing]
        = useState<ServiceProgram>(initialServiceProgram);

    const {showError, showSuccess} = useUserNotification();
    const {isUserManagement} = useAuth();

    const assetOverPanel = useRef<any>(null);


    useMountEffect(() => {
        reload();
    });

    useUpdateEffect(() => {
        reload();
    }, [tableState]);

    const initFilters = () => {
        setTableState(prevState => ({
            ...prevState,
            ...{filters: serviceProgramFilterInitialState}
        }));
    };

    const hasSelected = () => {
        return selected && selected.length > 0;
    };

    const reload = () => {
        setBusy(true);

        const paging = tableStateToPage(tableState);
        const sorting = tableStateToSorting(tableState);
        const filter = pfsFilterToSpring(tableState.filters);

        serviceProgramService.findAll(filter, paging, sorting)
            .then(response => {
                setServicePrograms(response.content);
                setTotalRecords(response.totalElements);
                serviceProgramService.loadServicePrograms(response.content)
                    .then(response => setServiceProgramsAssets(response))
                    .catch(reason => showError({
                        summary: "Загрузка файлов планов обслуживания",
                        detail: errorMessage(reason)
                    }));

            })
            .catch(reason => {
                setServicePrograms([]);
                showError({
                    summary: "Загрузка планов обслуживания",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => {
                setBusy(false);
                setSelected([]);
            });
    };

    const deleteSelected = () => {
        if (hasSelected()) {
            setBusy(true);
            serviceProgramService.deleteAll(selected)
                .then(() => {
                    showSuccess({
                        summary: "Удаление планов обслуживания",
                        detail: "Выбранные планы обслуживания успешно удалены."
                    });
                    reload();
                })
                .catch(reason => showError({
                    summary: "Удаление планов обслуживания",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));
        }
    }

    const deleteConfirmation = () => {
        confirmDialog({
            message: "Вы действительно хотите удалить выбранные планы обслуживания? Операция необратима.",
            header: "Удаление плана обслуживания",
            icon: "pi pi-info-circle",
            acceptClassName: 'p-button-danger',
            acceptLabel: "Да",
            rejectLabel: "Нет",
            className: "app-confirmation-dialog",
            accept() {
                deleteSelected();
            }
        });
    };

    const editButtonTemplate = (serviceProgram: ServiceProgram) => {
        return <div className="flex flex-row gap-1">
            <EditButton onClick={() => {
                setDialogState({
                    mode: "edit",
                    entity: {...serviceProgram},
                    visible: true
                });
            }} tooltip="Редактировать карточку плана обслуживания"
            />
            <Button type="button"
                    icon="pi pi-paperclip"
                    className="p-button-rounded"
                    tooltip="Файлы"
                    tooltipOptions={{position: 'top'}}
                    onClick={(e) => {
                        setServiceProgramAssetEditing(serviceProgram);
                        assetOverPanel.current?.toggle(e);
                    }}
            />
        </div>
    };

    const assetsTemplate = (serviceProgram: ServiceProgram) => {
        if (serviceProgramsAssets) {
            let entry = serviceProgramsAssets
                .find(ip => ip.serviceProgram.id === serviceProgram.id);
            if (entry) {
                return (
                    <div className="flex flex-row gap-1">
                        {entry.assets.map(
                            (asset, index) =>
                                <Tag key={index}
                                     value={assetName(asset)}
                                     className="p-chip"
                                />
                        )}
                    </div>
                );
            }
        }
    };

    /* Шапка и подвал таблицы */

    const tableHeader = (
        <div className="flex flex-row gap-2 lg:justify-content-between">
            <div className="flex flex-row gap-1">
                <ReloadButton onClick={reload} disabled={busy}/>
                <FilterResetButton onClick={initFilters} disabled={busy}/>
                <CreateButton tooltip="Зарегистрировать новый план обслуживания"
                              onClick={() => {
                                  setDialogState({
                                      mode: "create",
                                      entity: {...initialServiceProgram},
                                      visible: true
                                  })
                              }}
                              disabled={busy}
                              className="ml-1"/>

                <DeleteButton onClick={deleteConfirmation}
                              tooltip="Удалить выбранные планы обслуживания"
                              visible={!isUserManagement()}
                              disabled={!hasSelected() || busy || isUserManagement()}
                />
            </div>

            <ColumnToggle columns={serviceProgramColumns(assetsTemplate)}
                          visibleFields={visibleFields}
                          onColumnToggle={fields => setVisibleFields(fields)}/>
        </div>
    );

    const tableFooter = `Всего ${servicePrograms.length} планов обслуживания`;


    /*
        Обработчики событий
    */

    const onPage = (event: DataTablePageEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{pagination: event}
        }));
    };

    const onSort = (event: DataTableSortEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{sorting: event}
        }));
    };

    const onFilter = (event: DataTableFilterEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{filters: event.filters}
        }));
    };


    return (
        <div className="container">
            <OverlayPanel ref={assetOverPanel}>
                <ServiceProgramAssets serviceProgram={serviceProgramAsset}
                                      onBind={() => reload()}
                                      onUnbind={() => reload()}
                                      onClose={e => assetOverPanel.current.hide()}/>
            </OverlayPanel>

            <Card title="Планы обслуживания">
                <DataTable value={servicePrograms} dataKey="id"
                           header={tableHeader} footer={tableFooter}
                           lazy loading={busy} first={tableState.pagination.first} totalRecords={totalRecords}
                           filterDisplay="menu" onFilter={onFilter} filters={tableState.filters} filterLocale="ru"
                           sortField={tableState.sorting.sortField} sortOrder={tableState.sorting.sortOrder}
                           onSort={onSort}
                           paginator rows={tableState.pagination.rows}
                           rowsPerPageOptions={serviceProgramRowsPerPage}
                           onPage={onPage}
                           selectionMode="multiple" selection={selected!}
                           onSelectionChange={e => setSelected(e.value)}
                           scrollable={true} size="small"
                           stateStorage="local" stateKey="nsg-pa-dt-service-programs"
                           emptyMessage="Нет данных">

                    <Column selectionMode="multiple"/>
                    <Column body={editButtonTemplate}/>

                    {visibleOnly(visibleFields, serviceProgramColumns(assetsTemplate))}

                </DataTable>
            </Card>


            {/* Диалог регистрации и редактирования программы страхования */}

            <ServiceProgramPropertiesDialog serviceProgram={dialogState.entity}
                                            mode={dialogState.mode}
                                            visible={dialogState.visible}
                                            onHide={() => setDialogState(prev => ({
                                                ...prev,
                                                ...{visible: false}
                                            }))}
                                            onCancel={() => setDialogState(prev => ({
                                                ...prev,
                                                ...{visible: false}
                                            }))}
                                            onSave={() => {
                                                setDialogState(prev => ({
                                                    ...prev,
                                                    ...{visible: false}
                                                }));
                                                reload();
                                            }}
            />

        </div>
    );
}