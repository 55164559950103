import {
    DataTable,
    DataTableDataSelectableEvent,
    DataTableFilterEvent,
    DataTablePageEvent,
    DataTableSortEvent
} from "primereact/datatable";
import useUserNotification from "../../../hooks/useUserNotification";
import React, {useState} from "react";
import {errorMessage} from "../../../helpers/axiosError";
import {useMountEffect, useStorage, useUpdateEffect} from "primereact/hooks";
import {confirmDialog} from "primereact/confirmdialog";
import ReloadButton from "../../common/buttons/ReloadButton";
import CreateButton from "../../common/buttons/CreateButton";
import DeleteButton from "../../common/buttons/DeleteButton";
import {Column} from "primereact/column";
import {useAuth} from "../../../security/AuthProvider";
import NotificationRecipientSpecial, {
    initialNotificationRecipient
} from "../../../interfaces/notification/NotificationRecipientSpecial";
import TableState from "../../../interfaces/TableState";
import {
    notificationRecipientFilterInitialState
} from "../../../pages/admin/notification/recipient/NotificationRecipienFilter";
import {
    notificationRecipientPaginationInitialState,
    notificationRecipientRowsPerPage,
    notificationRecipientSortingInitialState
} from "../../../pages/admin/notification/recipient/NotificationRecipienConstants";
import NotificationRecipientPropertiesDialog, {
    NotificationRecipientDialogState
} from "../../../pages/admin/notification/recipient/NotificationRecipientPropertiesDialog";
import {initialNotificationRecipientColumns, notificationRecipientColumns} from "./NotificationRecipienColumns";
import tableStateToPage from "../../common/dataTable/tableStateToPage";
import tableStateToSorting from "../../common/dataTable/tableStateToSorting";
import {pfsFilterToSpring} from "../../../helpers/primereact-turkraft";
import EditButton from "../../common/buttons/EditButton";
import FilterResetButton from "../../common/buttons/FilterResetButton";
import ColumnToggle, {visibleOnly} from "../../common/dataTable/ColumnToggle";
import NotificationRecipientChooserDialog from "./NotificationRecipientChooserDialog";
import {Button} from "primereact/button";
import ApiResponse from "../../../interfaces/response.interface";
import {notificationRecipientSpecialService} from "../../../service/notification/NotificationRecipientSpecialService";


export enum NotificationRecipientTableMode {
    DICTIONARY = "dictionary",  // Редактор справочника
    REFERENCE = "reference",    // Редактор ссылочных значений сущности
    CHOOSER = "chooser",        // Выбор значения из справочника
}

interface NotificationRecipientTableProps {
    mode: NotificationRecipientTableMode;
    values?: number[];
    nonSelectable?: number[] | undefined;
    showEdit?: boolean | undefined;

    onSelect?(recipient: NotificationRecipientSpecial): void;

    onChange?(recipients: number[]): void;
}

export default function NotificationRecipientTable(props: NotificationRecipientTableProps) {

    const lsColumnsKey = `nsg-pa-dt-notification-recipients-${props.mode}-cols`;
    const lsFilterKey = `nsg-pa-dt-notification-recipients-${props.mode}-filter`;

    const {showError, showSuccess} = useUserNotification();
    const {isAdmin, isUserManagement} = useAuth();

    const [tableState, setTableState] =
        useState<TableState>({
            filters: notificationRecipientFilterInitialState,
            pagination: notificationRecipientPaginationInitialState,
            sorting: notificationRecipientSortingInitialState
        });

    const [dialogState, setDialogState] =
        useState<NotificationRecipientDialogState>({
            mode: "edit",
            entity: {...initialNotificationRecipient},
            visible: false
        });

    const [chooserDialogVisible, setChooserDialogVisible] =
        useState<boolean>(false);

    const [busy, setBusy] =
        React.useState<boolean>(false);

    const [recipients, setRecipients] =
        useState<NotificationRecipientSpecial[]>([]);

    const [totalRecords, setTotalRecords] =
        useState<number>(0);

    const [selected, setSelected] =
        useState<NotificationRecipientSpecial[]>([]);

    const [visibleFields, setVisibleFields] =
        useStorage<string[]>(initialNotificationRecipientColumns(), lsColumnsKey);


    const reloadAllData = () => {
        setBusy(true);

        const filter = pfsFilterToSpring(tableState.filters);
        const paging = tableStateToPage(tableState);
        const sorting = tableStateToSorting(tableState);

        notificationRecipientSpecialService.findAll(filter, paging, sorting)
            .then(response => {
                setRecipients(response.content);
                setTotalRecords(response.totalElements);

            })
            .catch(reason => {
                setRecipients([]);
                showError({
                    summary: "Загрузка адресатов",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => {
                setBusy(false);
                setSelected([]);
            });
    };

    const reloadInValues = () => {
        if (!props.values || props.values.length === 0) return;
        setBusy(true);

        const paging = tableStateToPage(tableState);
        const sorting = tableStateToSorting(tableState);

        notificationRecipientSpecialService.in(props.values, paging, sorting)
            .then((response: ApiResponse<NotificationRecipientSpecial>) => {
                setRecipients(response.content);
                setTotalRecords(response.totalElements);

            })
            .catch(reason => {
                setRecipients([]);
                showError({
                    summary: "Загрузка адресатов",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => {
                setBusy(false);
                setSelected([]);
            });
    };

    const reload = () => {
        switch (props.mode) {
            case NotificationRecipientTableMode.DICTIONARY:
            case NotificationRecipientTableMode.CHOOSER:
                reloadAllData();
                break;
            case NotificationRecipientTableMode.REFERENCE:
                reloadInValues();
                break;
        }
    };

    useMountEffect(() => reload());
    useUpdateEffect(() => reload(), [tableState]);

    const initFilters = () => {
        setTableState(prevState => ({
            ...prevState,
            ...{filters: notificationRecipientFilterInitialState}
        }));
    };

    const hasSelected = () => selected && selected.length > 0;

    // @ts-ignore
    const ids: number[] = recipients.map(r => r.id);

    const isSelectable = (recipient: NotificationRecipientSpecial) => {
        if (props.nonSelectable) return !props.nonSelectable.some(id => id === recipient.id);
        return true;
    };

    const isRowSelectable = (event: DataTableDataSelectableEvent) => {
        return event.data ? isSelectable(event.data as NotificationRecipientSpecial) : true;
    };

    const rowClassName = (data: NotificationRecipientSpecial) => (isSelectable(data) ? '' : 'p-disabled');

    const deleteSelected = () => {
        if (hasSelected()) {
            setBusy(true);
            notificationRecipientSpecialService.deleteAll(selected)
                .then(() => {
                    showSuccess({
                        summary: "Удаление адресатов",
                        detail: "Выбранные адресаты успешно удалены."
                    });
                    reload();
                })
                .catch(reason => showError({
                    summary: "Удаление адресатов",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));
        }
    }


    const deleteConfirmation = () => {
        confirmDialog({
            message: "Вы действительно хотите удалить выбранных адресатов? Операция необратима.",
            header: "Удаление адресатов",
            icon: "pi pi-info-circle",
            acceptClassName: 'p-button-danger',
            acceptLabel: "Да",
            rejectLabel: "Нет",
            className: "app-confirmation-dialog",
            accept() {
                deleteSelected();
            }
        });
    };

    /* Actions */

    const rowActions = (r: NotificationRecipientSpecial) => {
        return <div className="flex flex-row gap-1">
            {(props.mode === NotificationRecipientTableMode.DICTIONARY || props.showEdit) &&
                <EditButton onClick={() => setDialogState({
                    mode: "edit",
                    entity: {...r},
                    visible: true
                })} tooltip="Редактировать"/>
            }
            {props.mode === NotificationRecipientTableMode.CHOOSER &&
                <Button onClick={() => (props.onSelect) && props.onSelect(r)}
                        icon="pi pi-external-link"
                        tooltip="Выбрать"
                />
            }
        </div>
    };

    const reloadAction = <ReloadButton onClick={reload}/>;

    const filterAction = <FilterResetButton onClick={initFilters}/>;

    const createAction = <CreateButton tooltip="Зарегистрировать нового адресата"
                                       onClick={() => {
                                           setDialogState({
                                               mode: "create",
                                               entity: {...initialNotificationRecipient},
                                               visible: true
                                           });
                                       }}
                                       disabled={busy}
                                       className="ml-1"
    />;

    const deleteAction = <DeleteButton onClick={deleteConfirmation}
                                       tooltip="Удалить адресатов"
                                       visible={!isUserManagement()}
                                       disabled={!hasSelected() || busy || isUserManagement()}
    />;

    const includeAction = <CreateButton id="include-action"
                                        onClick={() => {

                                            setChooserDialogVisible(true);
                                        }}
                                        tooltip="Добавить адресата в список"
                                        disabled={busy || !isAdmin()}
                                        className="ml-1"
    />;

    const excludeAction = <DeleteButton id="exclude-action"
                                        onClick={() => {
                                            const rs: NotificationRecipientSpecial[] =
                                                JSON.parse(JSON.stringify(recipients));

                                            for (const s of selected) {
                                                const i = rs.findIndex(r => s.id === r.id);
                                                if (i >= 0) rs.splice(i, 1);
                                            }

                                            setRecipients(rs);

                                            if (props.onChange)
                                                // @ts-ignore
                                                props.onChange(rs.map(r => r.id))
                                        }}
                                        tooltip="Исключить адресата из списка"
                                        disabled={!hasSelected() || busy || isUserManagement()}
    />;

    const tableActions = () =>
        <div className="flex flex-row gap-1">
            {reloadAction}
            {filterAction}
            {props.mode === NotificationRecipientTableMode.DICTIONARY &&
                <>
                    {createAction}
                    {deleteAction}
                </>
            }
            {props.mode === NotificationRecipientTableMode.REFERENCE &&
                <>
                    {includeAction}
                    {excludeAction}
                </>
            }
        </div>;


    /* Шапка и подвал таблицы */

    const tableHeader = (
        <div className="flex flex-row gap-1 lg:justify-content-between">
            {tableActions()}
            <ColumnToggle columns={notificationRecipientColumns}
                          visibleFields={visibleFields}
                          onColumnToggle={fields => setVisibleFields(fields)}/>
        </div>
    );

    const tableFooter = `Всего адресатов: ${recipients.length}`;


    /*
        Обработчики событий
    */

    const onPage = (event: DataTablePageEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{pagination: event}
        }));
    };

    const onSort = (event: DataTableSortEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{sorting: event}
        }));
    };

    const onFilter = (event: DataTableFilterEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{filters: event.filters}
        }));
    };

    return (
        <>
            <div className="container">
                <h1>Адресаты</h1>

                <DataTable value={recipients} dataKey="id"
                           header={tableHeader} footer={tableFooter}
                           lazy loading={busy} first={tableState.pagination.first} totalRecords={totalRecords}
                           filterDisplay="menu" onFilter={onFilter} filters={tableState.filters} filterLocale="ru"
                           sortField={tableState.sorting.sortField} sortOrder={tableState.sorting.sortOrder}
                           onSort={onSort}
                           paginator rows={tableState.pagination.rows}
                           rowsPerPageOptions={notificationRecipientRowsPerPage}
                           onPage={onPage}
                           selectionMode="checkbox" selection={selected!}
                           onSelectionChange={e => setSelected(e.value)}
                           isDataSelectable={isRowSelectable} rowClassName={rowClassName}
                           scrollable={true} size="small"
                           stateStorage="local" stateKey={lsFilterKey}
                           emptyMessage="Адресаты не назначены, или не зарегистрированы.">

                    {props.mode !== NotificationRecipientTableMode.CHOOSER &&
                        <Column selectionMode="multiple"/>
                    }

                    <Column body={rowActions}/>

                    {visibleOnly(visibleFields, notificationRecipientColumns)}
                </DataTable>
            </div>


            {/* Диалог регистрации и редактирования адресатов */}

            <NotificationRecipientPropertiesDialog recipient={dialogState.entity}
                                                   mode={dialogState.mode}
                                                   visible={dialogState.visible}
                                                   onHide={() => setDialogState(prev => ({
                                                       ...prev,
                                                       ...{visible: false}
                                                   }))}
                                                   onCancel={() => setDialogState(prev => ({
                                                       ...prev,
                                                       ...{visible: false}
                                                   }))}
                                                   onSave={() => {
                                                       setDialogState(prev => ({
                                                           ...prev,
                                                           ...{visible: false}
                                                       }));
                                                       reload();
                                                   }}
            />


            {/* Диалог выбора адресата */}

            <NotificationRecipientChooserDialog nonSelectable={ids}
                                                visible={chooserDialogVisible}
                                                onHide={() => {
                                                    setChooserDialogVisible(false);
                                                }}
                                                onSelect={s => {
                                                    setChooserDialogVisible(false);

                                                    const rs: NotificationRecipientSpecial[] =
                                                        JSON.parse(JSON.stringify(recipients));
                                                    rs.push(s);
                                                    setRecipients(rs);

                                                    if (props.onChange)
                                                        // @ts-ignore
                                                        props.onChange(rs.map(r => r.id))
                                                }}/>
        </>
    );
}