import React, {useState} from "react";
import {useMountEffect, useUpdateEffect} from "primereact/hooks";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {BlockUI} from "primereact/blockui";
import {Mode} from "node:fs";
import NotificationList from "../../../../interfaces/notification/NotificationList";
import NotificationListStatusDropdown from "../../../../components/notification/list/NotificationListStatusDropdown";
import useUserNotification from "../../../../hooks/useUserNotification";
import {errorMessage} from "../../../../helpers/axiosError";
import CancelButton from "../../../../components/common/buttons/CancelButton";
import TaxonomySelect from "../../../../components/common/taxonomy/TaxonomySelect";
import Notes from "../../../../components/common/Notes";
import NotificationRecipientTable, {
    NotificationRecipientTableMode
} from "../../../../components/notification/recipient/NotificationRecipientTable";
import {EntityDialogState} from "../../../../components/common/dialog/EntityDialogState";
import SaveButton from "../../../../components/common/buttons/SaveButton";
import {AxiosResponse} from "axios";
import {notificationListService} from "../../../../service/notification/NotificationListService";

export interface NotificationListDialogState
    extends EntityDialogState<NotificationList> {
}

interface NotificationListDialogProps {
    notificationList: NotificationList;
    mode: Mode;
    visible: boolean;

    onHide(): void;

    onCancel?(): void;

    onSave?(): void
}


export default function NotificationListPropertiesDialog(props: NotificationListDialogProps) {

    const title = props.mode === "create" ?
        "Регистрация нового списка рассылки" :
        "Редактирование списка рассылки";

    const [busy, setBusy] = useState<boolean>(false);

    const [notificationList, setNotificationList] =
        useState<NotificationList>(props.notificationList);

    const {showError, showSuccess} = useUserNotification();


    const reload = () => {
        setNotificationList(props.notificationList);
    };

    useMountEffect(() => {
        reload();
    });

    useUpdateEffect(() => {
        reload();
    }, [props.notificationList]);


    const save = () => {
        if (props.mode === "create") {
            setBusy(true);

            notificationListService.create(notificationList)
                .then((response: AxiosResponse<NotificationList>) => {
                    showSuccess({
                        summary: "Регистрация списка рассылки",
                        detail: `Список рассылки "${notificationList.name}" успешно зарегистрирован в системе.`
                    });
                    if (props.onSave) props.onSave();
                })
                .catch(reason => showError({
                    summary: "Регистрация списка рассылки",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));

        } else if (props.mode === "edit" && notificationList.id) {
            setBusy(true);
            //console.log(notificationList);
            notificationListService.update(notificationList.id, notificationList)
                .then(() => {
                    showSuccess({
                        summary: "Обновление списка рассылки",
                        detail: `Регистрационные данные списка рассылки "${notificationList.name}" успешно сохранены.`
                    });
                    if (props.onSave) props.onSave();

                })
                .catch(reason => showError({
                    summary: "Обновление списка рассылки",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));
        }
    };

    const footer = (
        <div>
            <SaveButton onClick={() => save()} disabled={busy}/>
            <CancelButton onClick={props.onCancel} disabled={busy}/>
        </div>
    );

    return (
        <BlockUI blocked={busy}>
            <Dialog header={title}
                    visible={props.visible}
                    onHide={props.onHide}
                    footer={footer}
                    className="app-dialog">

                <div className="flex flex-column gap-4">
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="name" className="font-bold">Наименование</label>
                        <InputText id="name"
                                   autoFocus
                                   value={notificationList.name || ""}
                                   onChange={(e) =>
                                       setNotificationList(prev => ({
                                           ...prev,
                                           name: e.target.value
                                       }))}/>
                    </div>
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="taxonomy" className="font-bold">Таксономия</label>
                        <TaxonomySelect value={notificationList.taxonomy ? notificationList.taxonomy : null}
                                        onChange={id =>
                                            setNotificationList(prev => ({
                                                ...prev,
                                                taxonomy: id
                                            }))}/>
                    </div>
                    <div className="flex flex-column gap-2 m-0">
                        <label className="font-bold">Получатели</label>
                        <NotificationRecipientTable mode={NotificationRecipientTableMode.REFERENCE}
                                                    values={notificationList.recipients}
                                                    onChange={recipients => {
                                                        setNotificationList(prev => ({
                                                            ...prev,
                                                            recipients: recipients
                                                        }))
                                                    }}/>
                    </div>
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="status" className="font-bold">Статус</label>
                        <NotificationListStatusDropdown id="status"
                                                        value={notificationList.status}
                                                        onChange={s =>
                                                            setNotificationList(prev => ({
                                                                ...prev,
                                                                status: s
                                                            }))}/>
                    </div>
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="notes" className="font-bold">Примечания</label>
                        <Notes id="notes"
                               value={notificationList.notes || ""}
                               tabIndex={4}
                               onChange={e =>
                                   setNotificationList(
                                       prev => ({
                                           ...prev,
                                           notes: e.target.value
                                       }))}
                        />
                    </div>
                </div>
            </Dialog>
        </BlockUI>
    );
};