import {Link} from "react-router-dom";
import React, {useState} from "react";
import DispatcherConsole from "../interfaces/DispatcherConsole.interface";
import PhoneOutput from "../components/common/PhoneOutput";
import {useMountEffect} from "primereact/hooks";

import {dispatcherConsoleService} from "../service/DispatcherConsoleService";

export default function HelpPage() {
    const [dispatcherConsole, setDispatcherConsole] =
        useState<DispatcherConsole | undefined>(undefined);

    useMountEffect(() => {
        dispatcherConsoleService.findAll()
            .then(response => {
                setDispatcherConsole(response.content.length > 0 ? response.content[0] : undefined);
            })
            .catch(reason => {
                console.error(reason);
                setDispatcherConsole(undefined);
            });
    });

    return (
        <div className="container">
            <div className="card">
                <h1>Помощь</h1>
                <div className="field">
                    <div className="label">Телефон диспетчерского пульта</div>
                    <div className="value">
                        {dispatcherConsole && <Link to={`tel:+7${dispatcherConsole.phone}`}><PhoneOutput
                            number={dispatcherConsole.phone}/></Link>}
                        {!dispatcherConsole && "Не назначен"}
                    </div>
                    <span className="note">Работает круглосуточно</span>
                </div>
                <div className="field">
                    <div className="label">Управление медицинского страхования</div>
                    <div className="value">
                        {dispatcherConsole &&
                            <Link to={`tel:+7${dispatcherConsole.phone}`}><PhoneOutput number={"4957870424"}/></Link>}
                        {!dispatcherConsole && "Не назначен"}
                    </div>
                    <span className="note">Будние дни, с 9:00 до 18:00</span>
                </div>
                <div className="field">
                    <div className="label">Режим работы</div>
                    <div className="value">понедельник - пятница с 9.00 до 18.00</div>
                    <div className="value">суббота, воскресенье - выходной</div>
                </div>
                <div className="field">
                    <div className="label">Контакты технической поддержки</div>
                    <div className="value"><Link
                        to={"mailto:support@nsg-ins.ru?subject=Обращение с сайта"}>support@nsg-ins.ru</Link></div>
                </div>
                {/*<div>
                Контакты отдела контроля качества
                feedback@nsg-ins.ru
            </div>*/}
            </div>
        </div>
    );
}