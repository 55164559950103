import React, {useState} from "react";
import {useMountEffect} from "primereact/hooks";
import {BlockUI} from "primereact/blockui";
import {DataTable} from "primereact/datatable";
import UserDataImportSettings from "../interfaces/UserDataImportSettings";
import {Column} from "primereact/column";
import useUserNotification from "../hooks/useUserNotification";
import {errorMessage} from "../helpers/axiosError";
import {Checkbox} from "primereact/checkbox";
import {userDataImportService} from "../service/exchange/excel/UserDataImportService";

export default function UserDataImportSettingsPanel() {

    const {showError} = useUserNotification();

    const [busy, setBusy] =
        useState<boolean>(false);

    const [settings, setSettings] =
        useState<UserDataImportSettings[]>([]);

    const [selected, setSelected] =
        useState<UserDataImportSettings[]>([]);

    const [skipEmptyValue, setSkipEmptyValue] =
        useState<boolean>(true);


    const reload = () => {
        userDataImportService.settings()
            .then(response => {
                setSettings(response.data);
                if (response.data) {
                    setSelected(response.data.filter(s => s.enabled));
                }
            })
            .catch(reason => {
                setSettings([]);
                setSelected([]);
                showError({
                    summary: "Загрузка настроек",
                    detail: errorMessage(reason)
                })
            })
            .finally(() => setBusy(false))
    }

    useMountEffect(() => reload());


    return (
        <div className="flex flex-column gap-4">
            <BlockUI blocked={busy}>
                <div className="flex flex-column gap-4">
                    <div className="flex flex-column gap-2">
                        <div className="flex flex-row flex-nowrap align-items-stretch gap-2">
                            <Checkbox id="skipEmptyValue"
                                      checked={skipEmptyValue}
                                      onChange={(e) => setSkipEmptyValue(e.checked || false)}/>
                            <div className="flex flex-column">
                                <label htmlFor="skipEmptyValue">Пропускать пустые ячейки</label>
                                <div className="note">Игнорировать пустые ячейки при сравнении записей базы данных и
                                    импортируемого файла.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h2>Настройки атрибутов</h2>
                        <DataTable value={settings} dataKey={"field"}
                                   selectionMode={"checkbox"}
                                   selection={selected}
                                   onSelectionChange={(e) => setSelected(e.value)}
                                   size={"small"}>

                            <Column selectionMode="multiple"
                                    headerStyle={{width: '3rem'}}/>
                            <Column header={"Название атрибута"}
                                    field={"title"}
                                    dataType={"text"}/>
                            <Column header={"Системное имя"}
                                    field={"field"}
                                    dataType={"text"}/>

                        </DataTable>
                    </div>
                </div>
            </BlockUI>
        </div>
    );
};