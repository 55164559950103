import axios, {AxiosResponse} from "axios";
import JwtAuthenticationResponse from "../interfaces/auth/JwtAuthenticationResponse";
import LoginRequest from "../interfaces/auth/LoginRequest";
import {API_AUTH_URL, LS_TOKEN_KEY, LS_USER_KEY, LS_USER_PROFILE_KEY} from "../config";
import IDictionary from "../interfaces/IDictionary";
import {setupAxios} from "../index";
import User from "../interfaces/User.interface";
import {jwtDecode} from "jwt-decode";
import {userService} from "./UserService";


export default class AuthService {

    async login(loginRequest: LoginRequest): Promise<JwtAuthenticationResponse> {
        const response: AxiosResponse<JwtAuthenticationResponse> =
            await axios.post<JwtAuthenticationResponse>(API_AUTH_URL + "/signin", loginRequest);
        return response.data;
    }

    logout() {
        localStorage.clear();
        window.location.href = "/login";
    }

    /*register(name: string, username: string, email: string, password: string) {
        return axios.post<SignUpRequest>(API_AUTH_URL + "/signup", {
            name,
            username,
            email,
            password
        });
    }*/

    jwtDecodeUserId(jwt: string) {
        const claims: IDictionary = jwtDecode(jwt);
        return Number(claims['sub']);
    }

    reload(): Promise<User> | undefined {
        let jwt = localStorage.getItem(LS_TOKEN_KEY);
        if (jwt) {
            const userId = this.jwtDecodeUserId(jwt);
            return userService
                .find(userId)
                .then((user: User) => {

                    if (!user)
                        throw new Error("Заданная комбинация имени пользователя и пароля не существует.");

                    localStorage.setItem(LS_USER_KEY, JSON.stringify(user));
                    localStorage.setItem(LS_USER_PROFILE_KEY, JSON.stringify(user.profile));

                    return user;
                });
        }
        return undefined;
    }

    jwtProcess(jwt: string): Promise<User> | undefined {
        if (jwt) {
            localStorage.setItem(LS_TOKEN_KEY, jwt);
            setupAxios();
            return this.reload();
        }
        return undefined;
    }

    /*jwtProcess(jwt: string): (undefined | Promise<void | AxiosError<ApiErrorResponse>>) {
        if (jwt) {
            const userId: number = this.decodeUserId(jwt);
            localStorage.setItem(LS_TOKEN_KEY, jwt);

            return userService
                .find(userId)
                .then((user: User) => {
                    if (!user)
                        throw new Error("Заданная комбинация имени пользователя и пароля не существует.");

                    localStorage.setItem(LS_USER_KEY, JSON.stringify(user));
                    localStorage.setItem(LS_USER_PROFILE_KEY, JSON.stringify(user.profile));
                    window.location.href = "/";
                });
        }
        return undefined;
    }*/
}
export const authService: AuthService = new AuthService();