import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import React, {useState} from "react";
import DispatcherConsole from "../../interfaces/DispatcherConsole.interface";
import useUserNotification from "../../hooks/useUserNotification";
import {errorMessage} from "../../helpers/axiosError";
import {BlockUI} from "primereact/blockui";
import {useUpdateEffect} from "primereact/hooks";
import InputPhone from "../../components/common/InputPhone";
import SaveButton from "../../components/common/buttons/SaveButton";
import CancelButton from "../../components/common/buttons/CancelButton";
import {EntityDialogState} from "../../components/common/dialog/EntityDialogState";
import {cleanPhone} from "../../helpers/phone";
import {dispatcherConsoleService} from "../../service/DispatcherConsoleService";


export interface DispatcherConsoleDialogState extends EntityDialogState<DispatcherConsole> {
}

interface Props {
    dialogState: DispatcherConsoleDialogState;

    onHide(): void;

    onCancel(): void;

    onSave(): void;
}

export default function DispatcherConsolePropertiesDialog(props: Props) {
    const title = props.dialogState.mode === "create" ?
        "Регистрация нового диспетчерского пульта" :
        "Редактирование диспетчерского пульта";

    const [busy, setBusy] =
        useState<boolean>(false);

    const [editedDispatcherConsole, setEditedDispatcherConsole] =
        useState<DispatcherConsole>(props.dialogState.entity);

    const {showError, showSuccess} = useUserNotification();

    useUpdateEffect(() => {
        setEditedDispatcherConsole(props.dialogState.entity);
    }, [props.dialogState.entity]);

    const update = () => {
        setBusy(true);
        editedDispatcherConsole.phone = cleanPhone(editedDispatcherConsole.phone);

        if (props.dialogState.mode === "create") {
            dispatcherConsoleService.create(editedDispatcherConsole)
                .then(() => {
                    showSuccess({
                        summary: title,
                        detail: `Диспетчерский пульт ${editedDispatcherConsole.name} успешно зарегистрирован в системе.`
                    });
                    if (props.onSave) props.onSave();

                })
                .catch(reason => {
                    showError({
                        summary: title,
                        detail: errorMessage(reason)
                    });
                })
                .finally(() => {
                    setBusy(false);
                });

        } else if (editedDispatcherConsole.id) {
            dispatcherConsoleService.update(editedDispatcherConsole.id, editedDispatcherConsole)
                .then(() => {
                    showSuccess({
                        summary: title,
                        detail: `Регистрационные данные диспетчерского пульта ${editedDispatcherConsole.name} успешно сохранены.`
                    });
                    if (props.onSave) props.onSave();

                })
                .catch(reason => {
                    showError({
                        summary: title,
                        detail: errorMessage(reason)
                    });
                })
                .finally(() => {
                    setBusy(false);
                })
        }

        props.onSave();
    };

    const footer = (
        <div>
            <SaveButton onClick={update} disabled={busy}/>
            <CancelButton onClick={props.onCancel} disabled={busy}/>
        </div>
    );


    return (
        <BlockUI blocked={busy}>
            <Dialog header={title}
                    visible={props.dialogState.visible}
                    onHide={props.onHide}
                    footer={footer}
                    className="app-dialog">

                <div className="flex flex-column gap-4">
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="name" className="font-bold">Название</label>
                        <InputText id="name" value={editedDispatcherConsole.name}
                                   required={true}
                                   onChange={(e) => {
                                       setEditedDispatcherConsole(editedDispatcherConsole => ({
                                           ...editedDispatcherConsole,
                                           ...{name: e.target.value}
                                       }))
                                   }}/>
                    </div>
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="phone" className="font-bold">Телефон</label>
                        <InputPhone id="phone" value={editedDispatcherConsole.phone}
                                    required={true}
                                    onChange={(e) => {
                                        setEditedDispatcherConsole(editedDispatcherConsole => ({
                                            ...editedDispatcherConsole,
                                            ...{phone: e.target.value ? e.target.value : ''}
                                        }));
                                    }}/>
                    </div>
                </div>
            </Dialog>
        </BlockUI>
    );
}