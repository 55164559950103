import {API_DATA_IMPORT_URL} from "../../../config";
import axios, {AxiosResponse} from "axios";
import {UserDataRow} from "../../../interfaces/UserDataRow";
import {ProcessingResult} from "../../../interfaces/ProcessingResult";
import UserDataImportSettings from "../../../interfaces/UserDataImportSettings";

export default class UserDataImportService {
    url: string = API_DATA_IMPORT_URL;

    async comparison(): Promise<AxiosResponse<ProcessingResult<UserDataRow[]>>> {
        const request: string = this.url + "/comparison";
        return await axios.get(request);
    }

    async settings(): Promise<AxiosResponse<UserDataImportSettings[]>> {
        const request: string = this.url + "/settings";
        return await axios.get(request);
    }

    /*async full(): Promise<UserDataRow[]> {
        const request: string = this.url + "/comparison/result/full";
        const response: AxiosResponse<UserDataRow[]> = await axios.get(request);
        return response.data;
    }*/

    async result(): Promise<ProcessingResult<UserDataRow[]>> {
        const request: string = this.url + "/result";
        const response: AxiosResponse<ProcessingResult<UserDataRow[]>> = await axios.get(request);
        return response.data;
    }

    /*async modified(): Promise<UserDataRow[]> {
        const request: string = this.url + "/comparison/result/modified";
        const response: AxiosResponse<UserDataRow[]> = await axios.get(request);
        return response.data;
    }*/

    async save(): Promise<AxiosResponse<any>> {
        const request: string = this.url + "/result";
        return await axios.patch(request);
    }

    async clear(): Promise<AxiosResponse<any>> {
        const request: string = this.url + "/result";
        return await axios.delete(request);
    }
}
export const userDataImportService: UserDataImportService = new UserDataImportService();