import {dateTimeRangeFilterTemplate, numberFilterTemplate} from "../filterTemplates";
import {Column} from "primereact/column";
import React from "react";
import AbstractEntity from "../../../../interfaces/AbstractEntity";
import NotificationList from "../../../../interfaces/notification/NotificationList";
import TaxonomyTagsLoader from "../../taxonomy/TaxonomyTagsLoader";
import TemporalUpdatableEntity from "../../../../interfaces/TemporalUpdatableEntity";
import {formattedDateTime} from "../bodyTemplates";


/*interface ColumnIdProps {
    // rowData: IdentifiedEntity;
}

export type Props = ColumnIdProps;


export default class ColumnId extends Component<Props> {
    render() {
        return (
            <Column key="id" field="id" header="УН" sortable dataType="numeric"
                    body={(rowData: IdentifiedEntity) => rowData.id}
                    filter filterElement={numberFilterTemplate}
                    style={{width: '3rem'}}
            />
        );
    }
}*/

export const columnId =
    <Column key="id" field="id" header="УН" sortable dataType="numeric"
            body={(rowData: AbstractEntity) => rowData.id}
            filter filterElement={numberFilterTemplate}
            style={{width: '3rem'}}/>

export const columnCreated =
    <Column key="created" field="created" header="Создан" sortable
            dataType="text" body={(rowData: TemporalUpdatableEntity) => formattedDateTime(rowData.created)}
            filter filterElement={dateTimeRangeFilterTemplate}
    />

export const columnUpdated =
    <Column key="updated" field="updated" header="Обновлен" sortable
            dataType="text" body={(rowData: NotificationList) => formattedDateTime(rowData.updated)}
            filter filterElement={dateTimeRangeFilterTemplate}
    />

export const columnTaxonomy =
    <Column key="taxonomy" field="taxonomy" header="Таксономия" sortable
            dataType="numeric" body={(rowData: NotificationList) =>
        <TaxonomyTagsLoader taxonomyIds={rowData.taxonomy ? [rowData.taxonomy] : []}/>}
    />