import {DataTable, DataTableFilterEvent, DataTablePageEvent, DataTableSortEvent} from "primereact/datatable";
import {Column} from "primereact/column";
import React, {useState} from "react";
import {confirmDialog} from "primereact/confirmdialog";
import TableState from "../../../interfaces/TableState";
import Organization, {initialOrganization} from "../../../interfaces/Organization";
import {useMountEffect, useStorage, useUpdateEffect} from "primereact/hooks";
import OrganizationPropertiesDialog, {OrganizationDialogState} from "./OrganizationPropertiesDialog";
import useUserNotification from "../../../hooks/useUserNotification";
import {useAuth} from "../../../security/AuthProvider";
import tableStateToPage from "../../../components/common/dataTable/tableStateToPage";
import tableStateToSorting from "../../../components/common/dataTable/tableStateToSorting";
import {pfsFilterToSpring} from "../../../helpers/primereact-turkraft";
import {errorMessage} from "../../../helpers/axiosError";
import CreateButton from "../../../components/common/buttons/CreateButton";
import FilterResetButton from "../../../components/common/buttons/FilterResetButton";
import ReloadButton from "../../../components/common/buttons/ReloadButton";
import DeleteButton from "../../../components/common/buttons/DeleteButton";
import EditButton from "../../../components/common/buttons/EditButton";
import ColumnToggle, {visibleOnly} from "../../../components/common/dataTable/ColumnToggle";
import {initialOrganizationColumns, organizationColumns} from "./OrganizationColumns";
import {
    organizationPaginationInitialState,
    organizationRowsPerPage,
    organizationSortingInitialState
} from "./OrganizationConstants";
import {organizationFilterInitialState} from "./OrganizationFilter";
import {organizationService} from "../../../service/OrganizationService";

export default function OrganizationsPage() {

    const [busy, setBusy] = useState(false);

    const [tableState, setTableState] =
        useState<TableState>({
            filters: organizationFilterInitialState,
            pagination: organizationPaginationInitialState,
            sorting: organizationSortingInitialState
        });

    const [dialogState, setDialogState] =
        useState<OrganizationDialogState>({
            mode: "edit",
            entity: {...initialOrganization},
            visible: false
        });


    const [organizations, setOrganizations] =
        useState<Organization[]>([]);

    const [totalRecords, setTotalRecords] =
        useState<number>(0);

    const [selected, setSelected] =
        useState<Organization[]>([]);

    const [visibleFields, setVisibleFields] =
        useStorage<string[]>(initialOrganizationColumns(), "nsg-pa-dt-organization-cols");

    const {showError, showSuccess} = useUserNotification();
    const {isUserManagement} = useAuth();

    const reload = () => {
        setBusy(true);

        const paging = tableStateToPage(tableState);
        const sorting = tableStateToSorting(tableState);
        const filter = pfsFilterToSpring(tableState.filters);

        organizationService.findAll(filter, paging, sorting)
            .then(response => {
                setOrganizations(response.content);
                setTotalRecords(response.totalElements);

            })
            .catch(reason => {
                setOrganizations([]);
                showError({
                    summary: "Загрузка организаций",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => {
                setBusy(false);
                setSelected([]);
            });
    };

    useMountEffect(() => reload());
    useUpdateEffect(() => reload(), [tableState]);

    const hasSelected = () => {
        return selected && selected.length > 0;
    };

    const deleteSelected = () => {
        if (hasSelected()) {
            setBusy(true);
            organizationService.deleteAll(selected)
                .then(() => {
                    showSuccess({
                        summary: "Удаление организации",
                        detail: "Выбранные организации успешно удалены."
                    });
                    reload();
                })
                .catch(reason => showError({
                    summary: "Удаление организации",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));
        }
    }

    const initFilters = () => {
        setTableState(prevState => ({
            ...prevState,
            ...{filters: organizationFilterInitialState}
        }));
    };

    const editButtonTemplate = (organization: Organization) => {
        return <div className="flex flex-row gap-1">
            <EditButton onClick={() => setDialogState({
                mode: "edit",
                entity: {...organization},
                visible: true
            })} tooltip="Редактировать карточку организации"/>
        </div>
    };

    const deleteConfirmation = () => {
        confirmDialog({
            message: "Вы действительно хотите удалить выбранные организации?",
            header: "Удаление организации",
            icon: "pi pi-info-circle",
            acceptClassName: 'p-button-danger',
            acceptLabel: "Да",
            rejectLabel: "Нет",
            className: "app-confirmation-dialog",
            accept() {
                deleteSelected();
            }
        });
    };


    /* Шапка и подвал таблицы */

    const tableHeader = (
        <div className="flex flex-row gap-1 lg:justify-content-between">
            <div className="flex flex-row gap-1">
                <ReloadButton onClick={reload}/>
                <FilterResetButton onClick={initFilters}/>
                <CreateButton tooltip="Зарегистрировать новую организацию"
                              onClick={() => {
                                  setDialogState({
                                      mode: "create",
                                      entity: {...initialOrganization},
                                      visible: true
                                  });
                              }}
                              disabled={busy}
                              className="ml-1"
                />
                <DeleteButton onClick={deleteConfirmation}
                              tooltip="Удалить организацию"
                              visible={!isUserManagement()}
                              disabled={!hasSelected() || busy || isUserManagement()}
                />
            </div>
            <ColumnToggle columns={organizationColumns}
                          visibleFields={visibleFields}
                          onColumnToggle={fields => setVisibleFields(fields)}/>
        </div>
    );

    const tableFooter = `Всего ${organizations.length} организаций`;


    /*
        Обработчики событий
    */

    const onPage = (event: DataTablePageEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{pagination: event}
        }));
    };

    const onSort = (event: DataTableSortEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{sorting: event}
        }));
    };

    const onFilter = (event: DataTableFilterEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{filters: event.filters}
        }));
    };


    return (
        <>
            <div className="container">
                <h1>Организации</h1>

                <DataTable value={organizations} dataKey="id"
                           header={tableHeader} footer={tableFooter}
                           lazy loading={busy} first={tableState.pagination.first} totalRecords={totalRecords}
                           filterDisplay="menu" onFilter={onFilter} filters={tableState.filters} filterLocale="ru"
                           sortField={tableState.sorting.sortField} sortOrder={tableState.sorting.sortOrder}
                           onSort={onSort}
                           paginator rows={tableState.pagination.rows}
                           rowsPerPageOptions={organizationRowsPerPage}
                           onPage={onPage}
                           selectionMode="multiple" selection={selected!}
                           onSelectionChange={e => setSelected(e.value)}
                           scrollable={true} size="small"
                           stateStorage="local" stateKey="nsg-pa-dt-organizations"
                           emptyMessage="Нет данных">

                    <Column selectionMode="multiple"/>
                    <Column body={editButtonTemplate}/>

                    {visibleOnly(visibleFields, organizationColumns)}
                </DataTable>
            </div>

            {/* Диалог регистрации и редактирования организации */}

            <OrganizationPropertiesDialog organization={dialogState.entity}
                                          mode={dialogState.mode}
                                          visible={dialogState.visible}
                                          onHide={() => setDialogState(prev => ({
                                              ...prev,
                                              ...{visible: false}
                                          }))}
                                          onCancel={() => setDialogState(prev => ({
                                              ...prev,
                                              ...{visible: false}
                                          }))}
                                          onSave={() => {
                                              setDialogState(prev => ({
                                                  ...prev,
                                                  ...{visible: false}
                                              }));
                                              reload();
                                          }}
            />
        </>
    );
}