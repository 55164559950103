import React, {ReactNode, useRef, useState} from "react";
import {useMountEffect} from "primereact/hooks";
import {classNames} from "primereact/utils";
import {BlockUI} from "primereact/blockui";
import {DataView} from 'primereact/dataview';
import {TicketOverview} from "../interfaces/ticket/TicketOverview";
import {ldtf} from "../helpers/dateTime";
import {Link} from "react-router-dom";
import UserTicketConditions from "./UserTicketConditions";
import UserTicketMessageForm from "./UserTicketMessageForm";
import {useAuth} from "../security/AuthProvider";
import {authorFullName} from "../helpers/tickets";
import {Message} from "primereact/message";
import {useAppSettings} from "../providers/AppSettingsProvider";
import TicketStatusOutput from "../components/ticket/TicketStatusOutput";
import {Tag} from "primereact/tag";
import {ticketService} from "../service/TicketService";
import {Button} from "primereact/button";
import {CSSTransition} from "primereact/csstransition";

export default function UserTicketsPage() {

    const [busy, setBusy] =
        useState<boolean>(false);

    const [ticketOverviews, setTicketOverviews] =
        useState<TicketOverview[]>([]);

    const [showMessageForm, setShowMessageForm] =
        useState<boolean>(false);

    const formContainerRef = useRef(null);
    const {isTherapist, isAdmin, isRegistered} = useAuth();
    const {appSettings} = useAppSettings();

    const reload = () => {
        setShowMessageForm(false);
        setBusy(true);

        ticketService.overview()
            .then(response => {
                setTicketOverviews(response.data);
            })
            .catch(reason => {
                console.error(reason);
                setTicketOverviews([]);
            })
            .finally(() => setBusy(false));
    };

    useMountEffect(() => reload());

    const renderText = (text: string) => {
        const maxLength = 200;
        if (text) {
            if (text.length >= maxLength) return text.slice(0, maxLength) + "...";
            else return text;
        }
        return "";
    }

    const ticketLink = (ticketId: number | null, text: string): ReactNode | null => {
        if (!ticketId)
            return <>{renderText(text)}</>;
        else
            return (
                <Link to={`/tickets/${ticketId}`}
                      style={{"textDecoration": "none"}}>
                    {renderText(text)}
                </Link>
            );
    }

    const ticketTemplate = (ticketOverview: TicketOverview, index: number) => {
        return (
            <div className="col-12" key={ticketOverview.ticket.id}>
                <div
                    className={classNames('flex flex-column py-4 gap-4', {'border-top-1 surface-border': index !== 0})}>
                    <div className="text-300 text-sm flex flex-row flex-wrap align-items-center gap-3">
                        <div>
                            №{ticketOverview.ticket.id}
                        </div>
                        {ticketOverview.notViewedCount > 0 &&
                            <div>
                                <Tag value={ticketOverview.notViewedCount} rounded severity="success"/>
                            </div>
                        }
                        <div>
                            {ldtf(ticketOverview.ticket.created)}
                        </div>
                        <div className="text-600">
                            <TicketStatusOutput status={ticketOverview.ticket.status}/>
                        </div>
                    </div>
                    <div className="flex flex-column flex-1">
                        {ticketOverview.ticket.topic &&
                            <div className="text-primary text-2xl font-bold text-900 mb-2">
                                {ticketLink(ticketOverview.ticket.id, ticketOverview.ticket.topic)}
                            </div>
                        }
                        <div className={ticketOverview.ticket.topic ? "" : "text-primary text-2xl font-bold text-900"}>
                            {ticketLink(ticketOverview.ticket.id, ticketOverview.lastMessage.text)}
                        </div>
                        {(isAdmin() || isTherapist()) &&
                            <div className="text-500 text-sm mt-3">
                                {authorFullName(ticketOverview.lastMessage)}
                            </div>
                        }
                    </div>
                    <div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                        {/*<Button id="ticket-view"
                                type="button"
                                icon="pi pi-fw pi-eye"
                                tooltip="Перейти к просмотру"
                                rounded
                                outlined
                                onClick={() => navigate(`/tickets/${ticketOverview.ticket.id}`)}
                        />*/}
                        {/*<Button id="ticket-delete"
                                type="button"
                                icon="pi pi-fw pi-trash"
                                tooltip="Удалить"
                                rounded
                                disabled
                                onClick={() => {
                                }}
                        />*/}
                    </div>
                </div>
            </div>
        );
    };

    const ticketsTemplate = (overviews: TicketOverview[]): ReactNode[] | undefined => {
        if (!overviews || overviews.length === 0) return undefined;
        return overviews.map((ticketOverview, index) => {
            return <div key={ticketOverview.ticket.id}
                        className="grid grid-nogutter">{ticketTemplate(ticketOverview, index)}</div>
        });
    }

    const hasTickets = () => {
        return ticketOverviews && ticketOverviews.length > 0;
    }

    return (
        <div className="container">
            {!appSettings.ticketServiceEnabled &&
                <h1>Сервис временно недоступен.</h1>
            }
            {appSettings.ticketServiceEnabled &&
                <div className="card">
                    <h1>Обращения</h1>
                    <BlockUI blocked={busy}>
                        {!(isTherapist() || isAdmin()) && isRegistered() &&
                            <>
                                <UserTicketConditions/>

                                <Button label="Создать обращение"
                                        type="button"
                                        onClick={() => setShowMessageForm(!showMessageForm)}
                                        className="mt-2"/>

                                <CSSTransition timeout={300}
                                               nodeRef={formContainerRef}
                                               in={showMessageForm}
                                               classNames="opacity-transition"
                                               unmountOnExit
                                               appear>
                                    <article ref={formContainerRef}
                                             className="mt-2">
                                        <UserTicketMessageForm ticket={undefined}
                                                               doSubmit={() => reload()}/>
                                    </article>
                                </CSSTransition>
                            </>
                        }
                        <article className="mt-5">
                            {hasTickets() &&
                                <DataView value={ticketOverviews}
                                          listTemplate={ticketsTemplate}
                                          paginator
                                          rows={10}/>

                            }
                            {!hasTickets() &&
                                <Message severity="info"
                                         text="В данный момент обращения не зарегистрированы. Попробуйте обновить страницу позже."/>
                            }
                        </article>
                    </BlockUI>
                </div>
            }
        </div>
    );
}