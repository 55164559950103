import React, {useState} from "react";
import {DataTable, DataTableFilterEvent, DataTablePageEvent, DataTableSortEvent} from "primereact/datatable";
import {Column, ColumnFilterElementTemplateOptions} from "primereact/column";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {pfsFilterToSpring} from "../../helpers/primereact-turkraft";
import {MultiSelect} from "primereact/multiselect";
import {useMountEffect, useUpdateEffect} from "primereact/hooks";
import {logFilterInitialState} from "./LogTable.init.filter";
import useUserNotification from "../../hooks/useUserNotification";
import {errorMessage} from "../../helpers/axiosError";
import {DateTime} from "luxon";
import TableState from "../../interfaces/TableState";
import {LogRecord} from "../../interfaces/LogRecord.interface";
import tableStateToPage from "../common/dataTable/tableStateToPage";
import tableStateToSorting from "../common/dataTable/tableStateToSorting";
import ApiResponse, {ApiErrorResponse} from "../../interfaces/response.interface";
import {AxiosError} from "axios";
import {dateTimeFilterTemplate, numberFilterTemplate, textFilterTemplate} from "../common/dataTable/filterTemplates";
import ReloadButton from "../common/buttons/ReloadButton";
import UserProfileFullNameOutput from "../common/UserProfileFullNameOutput";
import {LogLevels} from "../../interfaces/dictionaries/LogLevels";
import {LogTypes} from "../../interfaces/dictionaries/LogTypes";
import {logService} from "../../service/LogService";


export default function LogTable() {
    const paginationInitialState: DataTablePageEvent = {
        first: 0,
        rows: 20,
        page: 0,
    };
    const sortingInitialState: DataTableSortEvent = {
        sortField: 'dateTime',
        sortOrder: -1,
        multiSortMeta: []
    };
    const [loading, setLoading] = useState<boolean>(false);
    const [tableState, setTableState] = useState<TableState>({
        filters: logFilterInitialState,
        pagination: paginationInitialState,
        sorting: sortingInitialState
    });
    const [logRecords, setLogRecords] = useState<LogRecord[]>([]);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const {showError, showInfo} = useUserNotification();


    const reload = () => {
        setLoading(true);

        const paging = tableStateToPage(tableState);
        const sorting = tableStateToSorting(tableState);
        const filter = pfsFilterToSpring(tableState.filters);

        logService.findAll(filter, paging, sorting)
            .then((response: ApiResponse<LogRecord>) => {
                setTotalRecords(response.totalElements);
                setLogRecords(response.content);
            })
            .catch((reason: AxiosError<ApiErrorResponse>) => {
                showError({
                    summary: "Ошибка загрузки журнала",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useMountEffect(() => {
        reload();
    });

    useUpdateEffect(() => {
        reload();
    }, [tableState]);


    const initFilters = () => {
        setTableState(prevState => ({
            ...prevState,
            ...{filters: logFilterInitialState}
        }));
    };


    /*
     * Обработчики событий
     */

    const onPage = (event: DataTablePageEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{pagination: event}
        }));
    };

    const onSort = (event: DataTableSortEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{sorting: event}
        }));
    };

    const onFilter = (event: DataTableFilterEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{filters: event.filters}
        }));
    };


    /* Дата события */

    const dateTimeBodyTemplate = (rowData: LogRecord) => {
        return <>{DateTime.fromISO(rowData.dateTime)
            .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}</>;
    };


    /* Уровни событий */

    const levelFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return <Dropdown value={options.value}
                         options={LogLevels} optionValue="code" optionLabel="name"
                         onChange={(e) => options.filterApplyCallback(e.value, options.index)}
                         placeholder="Выберите тип"
                         className="p-column-filter" showClear/>;
    };

    const levelBodyTemplate = (rowData: LogRecord) => {
        const level = LogLevels.find(l => l.code === rowData.level);
        return <span className={`level-${rowData.level}`}>{level ? level.name : ''}</span>;
    };


    /* Тип событий */

    const typeBodyTemplate = (rowData: LogRecord) => {
        let type = LogTypes.find(value => value.code === rowData.type);
        return <span className={`type-${rowData.type}`}>{type ? type.name : ''}</span>
    };

    const typeFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return <Dropdown value={options.value}
                         options={LogTypes} optionValue="code" optionLabel="name"
                         onChange={(e) => options.filterApplyCallback(e.value, options.index)}
                         placeholder="Выберите тип"
                         className="p-column-filter" showClear/>;
    };


    /* Колонки таблицы */

    const columns: ({ field: string; column: JSX.Element; hidden: boolean, header: string })[] = [
        {
            field: 'id',
            header: 'УН',
            hidden: true,
            column: <Column key="id" field="id" header="УН" sortable
                            filter filterElement={numberFilterTemplate}
                            style={{width: '3rem'}}
            />
        },
        {
            field: 'dateTime',
            header: 'Дата и время',
            hidden: false,
            column: <Column field="dateTime" header="Дата и время"
                            dataType="date" sortable
                            body={dateTimeBodyTemplate}
                            filter filterElement={dateTimeFilterTemplate}
                            style={{minWidth: '10rem'}}
            />
        },
        {
            field: 'type',
            header: 'Тип записи',
            hidden: false,
            column: <Column field="type" header="Тип записи" sortable
                            body={typeBodyTemplate}
                            filter filterElement={typeFilterTemplate}
                            style={{minWidth: '10rem'}}
            />
        },
        {
            field: 'level',
            header: 'Уровень',
            hidden: false,
            column: <Column field="level" header="Уровень" sortable
                            body={levelBodyTemplate}
                            filter filterElement={levelFilterTemplate}
                            style={{minWidth: '10rem'}}
            />
        },
        {
            field: 'source',
            header: 'Источник',
            hidden: false,
            column: <Column field="source" header="Источник" sortable
                            style={{minWidth: '10rem'}}
            />
        },
        {
            field: 'profile',
            header: 'Пользователь',
            hidden: false,
            column: <Column field="profile" header="Пользователь" sortable
                            body={(logRecord: LogRecord) => <UserProfileFullNameOutput profileId={logRecord.profile}/>}
                            style={{minWidth: '10rem'}}
            />
        },
        {
            field: 'description',
            header: 'Описание',
            hidden: false,
            column: <Column field="description" header="Описание" sortable
                            filter filterElement={textFilterTemplate}
                            style={{minWidth: 'auto'}}
            />
        },
        {
            field: 'created',
            header: 'Создано',
            hidden: true,
            column: <Column field="created" header="Создано" sortable
                            style={{width: '10rem'}}
            />
        }
    ];


    const [visibleColumnFields, setVisibleColumnFields] = useState<string[]>(
        columns.filter(column => !column.hidden).map(column => column.field)
    );

    const onColumnToggle = (event: any) => {
        let selectedColFields: string[] = event.value;
        let orderedColFields: string[] = columns
            .filter(col => selectedColFields.some(sField => sField === col.field))
            .map(col => col.field);

        setVisibleColumnFields(orderedColFields);
    };


    /* Шапка и подвал таблицы */

    const tableHeader = (
        <div className="flex justify-content-between">
            <ReloadButton onClick={reload}
                          disabled={loading}/>

            <Button icon="pi pi-fw pi-filter-slash"
                    tooltip="Сбросить фильтр" tooltipOptions={{position: 'top'}}
                    onClick={() => {
                        initFilters();
                        showInfo({
                            summary: "Таблица журнала",
                            detail: "Настройки фильтра сброшены."
                        });
                    }}
                    className="p-button-outlined p-button-sm ml-1"
            />

            {/*<Button label="Экспортировать"
                        tooltip="Выгрузить все, или выбранные табличные данные в файл"
                        tooltipOptions={{position: 'top'}}/>*/}

            <MultiSelect value={visibleColumnFields}
                         options={columns} optionLabel="header" optionValue="field"
                         onChange={onColumnToggle}
                         className="w-20rem ml-auto"
            />
        </div>
    );

    const tableFooter = `Всего ${totalRecords} строк журнала`;


    return (
        <DataTable value={logRecords} dataKey="id"
                   header={tableHeader} footer={tableFooter}
                   lazy loading={loading} first={tableState.pagination.first} totalRecords={totalRecords}
                   filterDisplay="menu" onFilter={onFilter} filters={tableState.filters} filterLocale="ru"
                   sortField={tableState.sorting.sortField} sortOrder={tableState.sorting.sortOrder} onSort={onSort}
                   paginator rows={tableState.pagination.rows} rowsPerPageOptions={[10, 20, 50, 100]} onPage={onPage}
                   size="small"
                   stateStorage={"session"} stateKey="dt-state-log-local"
                   emptyMessage="Нет данных">

            {visibleColumnFields.map((vField) => {
                let column = columns.filter(col => col.field === vField).map(col => col.column);
                return (column);
            })}
        </DataTable>
    );
}