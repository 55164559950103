import {ldtf} from "../helpers/dateTime";
import {TicketMessageDto} from "../interfaces/ticket/TicketMessageDto";
import {useAuth} from "../security/AuthProvider";
import useUserNotification from "../hooks/useUserNotification";
import {errorMessage} from "../helpers/axiosError";
import {useState} from "react";
import TicketMessageStatusOutput from "../components/ticketMessage/TicketMessageStatusOutput";
import {Ticket} from "../interfaces/ticket/Ticket";
import TicketMessagesAssetsOutput from "./admin/ticket-messages/TicketMessagesAssetsOutput";
import {Tag} from "primereact/tag";
import {Link} from "react-router-dom";
import {ticketMessageService} from "../service/TicketMessageService";

interface UserTicketMessageProps {
    ticket: Ticket;
    message: TicketMessageDto;
}

export default function UserTicketMessage({ticket, message}: UserTicketMessageProps) {
    const {userProfile, isAdmin, isUserManagement, isTherapist} = useAuth();
    const {showSuccess, showError} = useUserNotification();

    const [voted, setVoted] =
        useState<boolean>(false);

    const userFullName = [
        message.profileSecondName,
        message.profileFirstName,
        message.profileThirdName
    ].filter(un => (un && un.length > 0)).join(" ");

    const bg = () => {
        return message.sticky ? "bg-blue-50" :
            userProfile.id === message.profileId ? "bg-green-50" : "";
    };

    const isPriveleged = () => {
        return isAdmin() || isUserManagement() || isTherapist();
    }

    const isOwner = () => {
        return message.profileId === userProfile.id;
    };

    const like = () => {
        ticketMessageService.like(message.id)
            .then(() => {
                setVoted(true);
                showSuccess({
                    summary: "Голосование",
                    detail: "Спасибо! Ваш положительный отклик засчитан."
                });
            })
            .catch(reason => showError({
                summary: "Голосование",
                detail: errorMessage(reason)
            }));
    };

    const dislike = () => {
        ticketMessageService.dislike(message.id)
            .then(() => {
                setVoted(true);
                showSuccess({
                    summary: "Голосование",
                    detail: "Спасибо! Ваш отрицательный отклик засчитан."
                });
            })
            .catch(reason => showError({
                summary: "Голосование",
                detail: errorMessage(reason)
            }));
    };

    /*const actions = (
        <>
            <Button id="like"
                    type="button"
                    onClick={like}
                    rounded
                // outlined
                    text
                    disabled={voted || message.status !== TicketMessageStatus.VISIBLE}
                    icon="pi pi-thumbs-up-fill"/>

            <Button id="dislike"
                    type="button"
                    onClick={dislike}
                    rounded
                // outlined
                    text
                    disabled={voted || message.status !== TicketMessageStatus.VISIBLE}
                    icon="pi pi-thumbs-down-fill"/>
        </>
    );*/

    return (
        <div className={"flex flex-row gap-4 align-items-stretch p-3 xl:p-5 " + bg}>
            <div><i className="pi pi-user text-4xl xl:text-6xl"></i></div>
            <div className="w-full flex flex-column gap-3">
                <div className="flex flex-row flex-wrap gap-2 align-items-center">
                    {isPriveleged() && message.profileId &&
                        <Link to={`/profile/${message.profileId}`}>
                            <div className="font-bold">{userFullName}</div>
                        </Link>
                    }
                    {!isPriveleged() &&
                        <div className="font-bold">{userFullName}</div>
                    }
                    {!message.viewed &&
                        <Tag value={"Новое"} severity="success"/>
                    }
                </div>
                <div>{message.text}</div>
                <div>
                    <div className="flex flex-row flex-wrap justify-content-between align-items-center gap-1">
                        <div className="text-sm text-400">{ldtf(message.created)}</div>
                        <div className="flex flex-row gap-2 align-items-center">
                            <div className="text-sm text-400"><TicketMessageStatusOutput status={message.status}/></div>
                            {/*{!isOwner() && ticket.status !== TicketStatus.BLOCKED && actions}*/}
                        </div>
                        <div className="attachments my-2"><TicketMessagesAssetsOutput ticketMessage={message}/></div>
                    </div>
                </div>
            </div>
        </div>
    );
}