import {DataTable, DataTableFilterEvent, DataTablePageEvent, DataTableSortEvent} from "primereact/datatable";
import {Column} from "primereact/column";
import React, {useRef, useState} from "react";
import {confirmDialog} from "primereact/confirmdialog";
import {useMountEffect, useStorage, useUpdateEffect} from "primereact/hooks";
import InsuranceProgram, {initialInsuranceProgram} from "../../interfaces/InsuranceProgram.interface";
import ReloadButton from "../../components/common/buttons/ReloadButton";
import {errorMessage} from "../../helpers/axiosError";
import useUserNotification from "../../hooks/useUserNotification";
import FilterResetButton from "../../components/common/buttons/FilterResetButton";
import ColumnToggle, {visibleOnly} from "../../components/common/dataTable/ColumnToggle";
import CreateButton from "../../components/common/buttons/CreateButton";
import DeleteButton from "../../components/common/buttons/DeleteButton";
import EditButton from "../../components/common/buttons/EditButton";
import InsuranceProgramPropertiesDialog, {InsuranceProgramDialogState} from "./InsuranceProgramPropertiesDialog";
import TableState from "../../interfaces/TableState";
import {initialInsuranceProgramColumns, insuranceProgramColumns} from "./InsuranceProgramColumns";
import {insuranceProgramFilterInitialState} from "./InsuranceProrgramFilter";
import {
    insuranceProgramPaginationInitialState,
    insuranceProgramRowsPerPage,
    insuranceProgramSortingInitialState
} from "./InsuranceProgramConstants";
import tableStateToPage from "../../components/common/dataTable/tableStateToPage";
import tableStateToSorting from "../../components/common/dataTable/tableStateToSorting";
import {pfsFilterToSpring} from "../../helpers/primereact-turkraft";
import {InsuranceProgramsAssets, insuranceProgramService} from "../../service/InsuranceProgramService";
import {assetName} from "../../helpers/assets";
import {Tag} from "primereact/tag";
import {OverlayPanel} from "primereact/overlaypanel";
import InsuranceProgramAssets from "./InsuranceProgramAssets";
import {Button} from "primereact/button";
import {useAuth} from "../../security/AuthProvider";

export default function InsuranceProgramsPage() {

    const [busy, setBusy] = useState(false);

    const [tableState, setTableState] =
        useState<TableState>({
            filters: insuranceProgramFilterInitialState,
            pagination: insuranceProgramPaginationInitialState,
            sorting: insuranceProgramSortingInitialState
        });

    const [dialogState, setDialogState] =
        useState<InsuranceProgramDialogState>({
            mode: "edit",
            entity: {...initialInsuranceProgram},
            visible: false
        });


    const [insurancePrograms, setInsurancePrograms] =
        useState<InsuranceProgram[]>([]);

    const [insuranceProgramsAssets, setInsuranceProgramsAssets] =
        useState<InsuranceProgramsAssets[] | undefined>(undefined);

    const [totalRecords, setTotalRecords] =
        useState<number>(0);

    const [selected, setSelected] =
        useState<InsuranceProgram[]>([]);

    const [visibleFields, setVisibleFields] =
        useStorage<string[]>(initialInsuranceProgramColumns(), "nsg-pa-dt-insurance-program-cols");

    const [insuranceProgramAsset, setInsuranceProgramAsset]
        = useState<InsuranceProgram>(initialInsuranceProgram);

    const assetsOverPanel = useRef<any>(null);


    const {showError, showSuccess} = useUserNotification();
    const {isUserManagement} = useAuth();

    const reload = () => {
        setBusy(true);

        const paging = tableStateToPage(tableState);
        const sorting = tableStateToSorting(tableState);
        const filter = pfsFilterToSpring(tableState.filters);

        insuranceProgramService.findAll(filter, paging, sorting)
            .then(response => {
                setInsurancePrograms(response.content);
                setTotalRecords(response.totalElements);
                insuranceProgramService.loadInsurancePrograms(response.content)
                    .then(response => setInsuranceProgramsAssets(response))
                    .catch(reason => showError({
                        summary: "Загрузка файлов программ страхования",
                        detail: errorMessage(reason)
                    }));

            })
            .catch(reason => {
                setInsurancePrograms([]);
                showError({
                    summary: "Загрузка программ страхования",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => {
                setBusy(false);
                setSelected([]);
            });
    };

    useMountEffect(() => reload());
    useUpdateEffect(() => reload(), [tableState]);

    const hasSelected = () => {
        return selected && selected.length > 0;
    };

    const actionDeleteSelected = () => {
        if (hasSelected()) {
            setBusy(true);
            insuranceProgramService.deleteAll(selected)
                .then(() => {
                    showSuccess({
                        summary: "Удаление программы страхования",
                        detail: "Выбранные программы страхования успешно удалены."
                    });
                    reload();
                })
                .catch(reason => showError({
                    summary: "Удаление программы страхования",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));
        }
    }

    const initFilters = () => {
        setTableState(prevState => ({
            ...prevState,
            ...{filters: insuranceProgramFilterInitialState}
        }));
    };

    const actions = (insuranceProgram: InsuranceProgram) => {
        return <div className="flex flex-row gap-1">
            <EditButton onClick={() => {
                setDialogState({
                    mode: "edit",
                    entity: {...insuranceProgram},
                    visible: true
                });
            }} tooltip="Редактировать карточку программы страхования"/>
            <Button type="button"
                    icon="pi pi-paperclip"
                    className="p-button-rounded"
                    tooltip="Файлы"
                    tooltipOptions={{position: 'top'}}
                    onClick={(e) => {
                        setInsuranceProgramAsset(insuranceProgram);
                        assetsOverPanel.current?.toggle(e);
                    }}/>
        </div>
    };

    const deleteConfirmation = () => {
        confirmDialog({
            message: "Вы действительно хотите удалить выбранные программы страхования?",
            header: "Удаление программы страхования",
            icon: "pi pi-info-circle",
            acceptClassName: 'p-button-danger',
            acceptLabel: "Да",
            rejectLabel: "Нет",
            className: "app-confirmation-dialog",
            accept() {
                actionDeleteSelected();
            }
        });
    };


    const assetsTemplate = (insuranceProgram: InsuranceProgram) => {
        if (insuranceProgramsAssets) {
            let entry = insuranceProgramsAssets.find(ip => ip.insuranceProgram.id === insuranceProgram.id);
            if (entry) {
                return (
                    <div className="flex flex-row gap-1">
                        {entry.assets.map(
                            (asset, index) =>
                                <Tag key={index}
                                    //onClick={() => mediaAssetService.downloadAsset(asset)}
                                    /*onClick={(e) => {
                                        setInsuranceProgramAsset(insuranceProgram);
                                        assetsOverPanel.current?.toggle(e);
                                    }}*/
                                     value={assetName(asset)}
                                     className="p-chip"
                                />
                        )}
                    </div>
                );
            }
        }
    };


    /* Шапка и подвал таблицы */

    const tableHeader = (
        <div className="flex flex-row gap-1 lg:justify-content-between">
            <div className="flex flex-row gap-1">
                <ReloadButton onClick={reload}/>
                <FilterResetButton onClick={initFilters}/>
                <CreateButton tooltip="Зарегистрировать новую программу страхования"
                              onClick={() => {
                                  setDialogState({
                                      mode: "create",
                                      entity: {...initialInsuranceProgram},
                                      visible: true
                                  });
                              }}
                              disabled={busy}
                              className="ml-1"
                />
                <DeleteButton onClick={deleteConfirmation}
                              tooltip="Удалить программу страхования"
                              visible={!isUserManagement()}
                              disabled={!hasSelected() || busy || isUserManagement()}
                />
            </div>
            <ColumnToggle columns={insuranceProgramColumns(assetsTemplate)}
                          visibleFields={visibleFields}
                          onColumnToggle={fields => setVisibleFields(fields)}/>
        </div>
    );

    const tableFooter = `Всего ${insurancePrograms.length} программ страхования`;


    /*
        Обработчики событий
    */

    const onPage = (event: DataTablePageEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{pagination: event}
        }));
    };

    const onSort = (event: DataTableSortEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{sorting: event}
        }));
    };

    const onFilter = (event: DataTableFilterEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{filters: event.filters}
        }));
    };


    return (
        <>
            <div className="container">
                <h1>Программы страхования</h1>
                <DataTable value={insurancePrograms} dataKey="id"
                           header={tableHeader} footer={tableFooter}
                           lazy loading={busy} first={tableState.pagination.first} totalRecords={totalRecords}
                           filterDisplay="menu" onFilter={onFilter} filters={tableState.filters} filterLocale="ru"
                           sortField={tableState.sorting.sortField} sortOrder={tableState.sorting.sortOrder}
                           onSort={onSort}
                           paginator rows={tableState.pagination.rows}
                           rowsPerPageOptions={insuranceProgramRowsPerPage}
                           onPage={onPage}
                           selectionMode="multiple" selection={selected!}
                           onSelectionChange={e => setSelected(e.value)}
                           scrollable={true} size="small"
                           stateStorage="local" stateKey="nsg-pa-dt-insurance-programs"
                           emptyMessage="Нет данных">

                    <Column selectionMode="multiple"/>
                    <Column body={actions}/>

                    {visibleOnly(visibleFields, insuranceProgramColumns(assetsTemplate))}

                </DataTable>
            </div>


            <OverlayPanel ref={assetsOverPanel}>
                <InsuranceProgramAssets insuranceProgram={insuranceProgramAsset}
                                        onBind={() => reload()}
                                        onUnbind={() => reload()}
                                        onClose={() => assetsOverPanel.current.hide()}/>
            </OverlayPanel>


            {/* Диалог регистрации и редактирования программы страхования */}

            <InsuranceProgramPropertiesDialog insuranceProgram={dialogState.entity}
                                              mode={dialogState.mode}
                                              visible={dialogState.visible}
                                              onHide={() => setDialogState(prev => ({
                                                  ...prev,
                                                  ...{visible: false}
                                              }))}
                                              onCancel={() => setDialogState(prev => ({
                                                  ...prev,
                                                  ...{visible: false}
                                              }))}
                                              onSave={() => {
                                                  setDialogState(prev => ({
                                                      ...prev,
                                                      ...{visible: false}
                                                  }));
                                                  reload();
                                              }}
            />
        </>
    );
}