import {API_THERAPISTS_URL} from "../config";
import ApiResponse from "../interfaces/response.interface";
import axios, {AxiosResponse} from "axios";
import Therapist from "../interfaces/Therapist";

export default class TherapistService {
    url: string = API_THERAPISTS_URL;

    async findAll(): Promise<ApiResponse<Therapist>> {
        const response: AxiosResponse<ApiResponse<Therapist>> = await axios.get(this.url);
        return response.data;
    }

    async find(id: number): Promise<Therapist> {
        const response: AxiosResponse<Therapist> = await axios.get<Therapist>(this.url + "/" + id);
        return response.data;
    }
}
export const therapistService: TherapistService = new TherapistService();