import {DataTable, DataTableFilterEvent, DataTablePageEvent, DataTableSortEvent} from "primereact/datatable";
import {Column} from "primereact/column";
import React, {useState} from "react";
import {confirmDialog} from "primereact/confirmdialog";
import {useMountEffect, useStorage, useUpdateEffect} from "primereact/hooks";
import NotificationListPropertiesDialog, {NotificationListDialogState} from "./NotificationListPropertiesDialog";
import TableState from "../../../../interfaces/TableState";
import tableStateToPage from "../../../../components/common/dataTable/tableStateToPage";
import tableStateToSorting from "../../../../components/common/dataTable/tableStateToSorting";
import {pfsFilterToSpring} from "../../../../helpers/primereact-turkraft";
import {errorMessage} from "../../../../helpers/axiosError";
import EditButton from "../../../../components/common/buttons/EditButton";
import ReloadButton from "../../../../components/common/buttons/ReloadButton";
import FilterResetButton from "../../../../components/common/buttons/FilterResetButton";
import CreateButton from "../../../../components/common/buttons/CreateButton";
import DeleteButton from "../../../../components/common/buttons/DeleteButton";
import ColumnToggle, {visibleOnly} from "../../../../components/common/dataTable/ColumnToggle";
import NotificationList, {initialNotificationList} from "../../../../interfaces/notification/NotificationList";
import useUserNotification from "../../../../hooks/useUserNotification";
import {useAuth} from "../../../../security/AuthProvider";
import {notificationListFilterInitialState} from "./NotificationListFilter";
import {
    notificationListPaginationInitialState,
    notificationListRowsPerPage,
    notificationListSortingInitialState
} from "./NotificationListConstants";
import {initialNotificationListColumns, notificationListColumns} from "./NotificationListColumns";

import {notificationListService} from "../../../../service/notification/NotificationListService";


export default function NotificationListPage() {

    const [busy, setBusy] = useState(false);

    const [tableState, setTableState] =
        useState<TableState>({
            filters: notificationListFilterInitialState,
            pagination: notificationListPaginationInitialState,
            sorting: notificationListSortingInitialState
        });

    const [dialogState, setDialogState] =
        useState<NotificationListDialogState>({
            mode: "edit",
            entity: {...initialNotificationList},
            visible: false
        });


    const [notificationLists, setNotificationLists] =
        useState<NotificationList[]>([]);

    const [totalRecords, setTotalRecords] =
        useState<number>(0);

    const [selected, setSelected] =
        useState<NotificationList[]>([]);

    const [visibleFields, setVisibleFields] =
        useStorage<string[]>(initialNotificationListColumns(), "nsg-pa-dt-notificationList-cols");

    const {showError, showSuccess} = useUserNotification();
    const {isUserManagement} = useAuth();

    useMountEffect(() => {
        reload();
    });

    useUpdateEffect(() => {
        reload();
    }, [tableState]);

    const hasSelected = () => {
        return selected && selected.length > 0;
    };

    const reload = () => {
        setBusy(true);

        const paging = tableStateToPage(tableState);
        const sorting = tableStateToSorting(tableState);
        const filter = pfsFilterToSpring(tableState.filters);

        notificationListService.findAll(filter, paging, sorting)
            .then(response => {
                setNotificationLists(response.content);
                setTotalRecords(response.totalElements);

            })
            .catch(reason => {
                setNotificationLists([]);
                showError({
                    summary: "Загрузка списков рассылки",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => {
                setBusy(false);
                setSelected([]);
            });
    };

    const deleteSelected = () => {
        if (hasSelected()) {
            setBusy(true);
            notificationListService.deleteAll(selected)
                .then(() => {
                    showSuccess({
                        summary: "Удаление списка рассылки",
                        detail: "Выбранные списка рассылки успешно удалены."
                    });
                    reload();
                })
                .catch(reason => showError({
                    summary: "Удаление списка рассылки",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));
        }
    }

    const initFilters = () => {
        setTableState(prevState => ({
            ...prevState,
            ...{filters: notificationListFilterInitialState}
        }));
    };

    const editButtonTemplate = (notificationList: NotificationList) => {
        return <div className="flex flex-row gap-1">
            <EditButton onClick={() => setDialogState({
                mode: "edit",
                entity: {...notificationList},
                visible: true
            })} tooltip="Редактировать карточку списка рассылки"/>
        </div>
    };

    const deleteConfirmation = () => {
        confirmDialog({
            message: "Вы действительно хотите удалить выбранные списки рассылки?",
            header: "Удаление списка рассылки",
            icon: "pi pi-info-circle",
            acceptClassName: 'p-button-danger',
            acceptLabel: "Да",
            rejectLabel: "Нет",
            className: "app-confirmation-dialog",
            accept() {
                deleteSelected();
            }
        });
    };


    /* Шапка и подвал таблицы */

    const tableHeader = (
        <div className="flex flex-row gap-1 lg:justify-content-between">
            <div className="flex flex-row gap-1">
                <ReloadButton onClick={reload}/>
                <FilterResetButton onClick={initFilters}/>
                <CreateButton tooltip="Зарегистрировать новый список рассылки"
                              onClick={() => {
                                  setDialogState({
                                      mode: "create",
                                      entity: {...initialNotificationList},
                                      visible: true
                                  });
                              }}
                              disabled={busy}
                              className="ml-1"
                />
                <DeleteButton onClick={deleteConfirmation}
                              tooltip="Удалить список рассылки"
                              visible={!isUserManagement()}
                              disabled={!hasSelected() || busy || isUserManagement()}
                />
            </div>
            <ColumnToggle columns={notificationListColumns}
                          visibleFields={visibleFields}
                          onColumnToggle={fields => setVisibleFields(fields)}/>
        </div>
    );

    const tableFooter = `Всего списков рассылки: ${notificationLists.length}`;


    /*
        Обработчики событий
    */

    const onPage = (event: DataTablePageEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{pagination: event}
        }));
    };

    const onSort = (event: DataTableSortEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{sorting: event}
        }));
    };

    const onFilter = (event: DataTableFilterEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{filters: event.filters}
        }));
    };


    return (
        <>
            <div className="container">
                <h1>Списки рассылки</h1>

                <DataTable value={notificationLists} dataKey="id"
                           header={tableHeader} footer={tableFooter}
                           lazy loading={busy} first={tableState.pagination.first} totalRecords={totalRecords}
                           filterDisplay="menu" onFilter={onFilter} filters={tableState.filters} filterLocale="ru"
                           sortField={tableState.sorting.sortField} sortOrder={tableState.sorting.sortOrder}
                           onSort={onSort}
                           paginator rows={tableState.pagination.rows}
                           rowsPerPageOptions={notificationListRowsPerPage}
                           onPage={onPage}
                           selectionMode="multiple" selection={selected!}
                           onSelectionChange={e => setSelected(e.value)}
                           scrollable={true} size="small"
                           stateStorage="local" stateKey="nsg-pa-dt-notificationLists"
                           emptyMessage="Нет данных">

                    <Column selectionMode="multiple"/>
                    <Column body={editButtonTemplate}/>

                    {visibleOnly(visibleFields, notificationListColumns)}
                </DataTable>
            </div>

            {/* Диалог регистрации и редактирования списка рассылки */}

            <NotificationListPropertiesDialog notificationList={dialogState.entity}
                                              mode={dialogState.mode}
                                              visible={dialogState.visible}
                                              onHide={() => setDialogState(prev => ({
                                                  ...prev,
                                                  ...{visible: false}
                                              }))}
                                              onCancel={() => setDialogState(prev => ({
                                                  ...prev,
                                                  ...{visible: false}
                                              }))}
                                              onSave={() => {
                                                  setDialogState(prev => ({
                                                      ...prev,
                                                      ...{visible: false}
                                                  }));
                                                  reload();
                                              }}
            />
        </>
    );
}