import {DataTable, DataTableFilterEvent, DataTablePageEvent, DataTableSortEvent} from "primereact/datatable";
import {Column} from "primereact/column";
import React, {useState} from "react";
import {confirmDialog} from "primereact/confirmdialog";
import {useMountEffect, useStorage, useUpdateEffect} from "primereact/hooks";
import TableState from "../../../../interfaces/TableState";
import tableStateToPage from "../../../../components/common/dataTable/tableStateToPage";
import tableStateToSorting from "../../../../components/common/dataTable/tableStateToSorting";
import {pfsFilterToSpring} from "../../../../helpers/primereact-turkraft";
import {errorMessage} from "../../../../helpers/axiosError";
import EditButton from "../../../../components/common/buttons/EditButton";
import ReloadButton from "../../../../components/common/buttons/ReloadButton";
import FilterResetButton from "../../../../components/common/buttons/FilterResetButton";
import CreateButton from "../../../../components/common/buttons/CreateButton";
import DeleteButton from "../../../../components/common/buttons/DeleteButton";
import ColumnToggle, {visibleOnly} from "../../../../components/common/dataTable/ColumnToggle";
import useUserNotification from "../../../../hooks/useUserNotification";
import {useAuth} from "../../../../security/AuthProvider";
import MessageTemplate, {initialMessageTemplate} from "../../../../interfaces/notification/MessageTemplate";
import {initialMessageTemplateColumns, messageTemplateColumns} from "./MessageTemplateColumns";
import {messageTemplateFilterInitialState} from "./MessageTemplateFilter";
import {
    messageTemplatePaginationInitialState,
    messageTemplateRowsPerPage,
    messageTemplateSortingInitialState
} from "./MessageTemplateConstants";
import MessageTemplatePropertiesDialog, {MessageTemplateDialogState} from "./MessageTemplatePropertiesDialog";
import {messageTemplateService} from "../../../../service/MessageTemplateService";


export default function MessageTemplatePage() {

    const [busy, setBusy] = useState(false);

    const [tableState, setTableState] =
        useState<TableState>({
            filters: messageTemplateFilterInitialState,
            pagination: messageTemplatePaginationInitialState,
            sorting: messageTemplateSortingInitialState
        });

    const [dialogState, setDialogState] =
        useState<MessageTemplateDialogState>({
            mode: "edit",
            entity: {...initialMessageTemplate},
            visible: false
        });


    const [messageTemplates, setMessageTemplates] =
        useState<MessageTemplate[]>([]);

    const [totalRecords, setTotalRecords] =
        useState<number>(0);

    const [selected, setSelected] =
        useState<MessageTemplate[]>([]);

    const [visibleFields, setVisibleFields] =
        useStorage<string[]>(initialMessageTemplateColumns(), "nsg-pa-dt-messageTemplates-cols");

    const {showError, showSuccess} = useUserNotification();
    const {isUserManagement} = useAuth();

    useMountEffect(() => {
        reload();
    });

    useUpdateEffect(() => {
        reload();
    }, [tableState]);

    const hasSelected = () => {
        return selected && selected.length > 0;
    };

    const reload = () => {
        setBusy(true);

        const paging = tableStateToPage(tableState);
        const sorting = tableStateToSorting(tableState);
        const filter = pfsFilterToSpring(tableState.filters);

        messageTemplateService.findAll(filter, paging, sorting)
            .then(response => {
                setMessageTemplates(response.content);
                setTotalRecords(response.totalElements);

            })
            .catch(reason => {
                setMessageTemplates([]);
                showError({
                    summary: "Загрузка списков рассылки",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => {
                setBusy(false);
                setSelected([]);
            });
    };

    const deleteSelected = () => {
        if (hasSelected()) {
            setBusy(true);
            messageTemplateService.deleteAll(selected)
                .then(() => {
                    showSuccess({
                        summary: "Удаление шаблонов сообщений",
                        detail: "Выбранные шаблоны сообщений успешно удалены."
                    });
                    reload();
                })
                .catch(reason => showError({
                    summary: "Удаление шаблонов сообщений",
                    detail: errorMessage(reason)
                }))
                .finally(() => setBusy(false));
        }
    }

    const initFilters = () => {
        setTableState(prevState => ({
            ...prevState,
            ...{filters: messageTemplateFilterInitialState}
        }));
    };

    const editButtonTemplate = (messageTemplate: MessageTemplate) => {
        return <div className="flex flex-row gap-1">
            <EditButton onClick={() => setDialogState({
                mode: "edit",
                entity: {...messageTemplate},
                visible: true
            })} tooltip="Редактировать карточку шаблона сообщений"/>
        </div>
    };

    const deleteConfirmation = () => {
        confirmDialog({
            message: "Вы действительно хотите удалить выбранные шаблоны сообщений? Операция необратима.",
            header: "Удаление шаблона сообщений",
            icon: "pi pi-info-circle",
            acceptClassName: 'p-button-danger',
            acceptLabel: "Да",
            rejectLabel: "Нет",
            className: "app-confirmation-dialog",
            accept() {
                deleteSelected();
            }
        });
    };


    /* Шапка и подвал таблицы */

    const tableHeader = (
        <div className="flex flex-row gap-1 lg:justify-content-between">
            <div className="flex flex-row gap-1">
                <ReloadButton onClick={reload}/>
                <FilterResetButton onClick={initFilters}/>
                <CreateButton tooltip="Зарегистрировать новый шаблон сообщений"
                              onClick={() => {
                                  setDialogState({
                                      mode: "create",
                                      entity: {...initialMessageTemplate},
                                      visible: true
                                  });
                              }}
                              disabled={busy}
                              className="ml-1"
                />
                <DeleteButton onClick={deleteConfirmation}
                              tooltip="Удалить шаблон сообщений"
                              visible={!isUserManagement()}
                              disabled={!hasSelected() || busy || isUserManagement()}
                />
            </div>
            <ColumnToggle columns={messageTemplateColumns}
                          visibleFields={visibleFields}
                          onColumnToggle={fields => setVisibleFields(fields)}/>
        </div>
    );

    const tableFooter = `Всего: ${messageTemplates.length}`;


    /*
        Обработчики событий
    */

    const onPage = (event: DataTablePageEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{pagination: event}
        }));
    };

    const onSort = (event: DataTableSortEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{sorting: event}
        }));
    };

    const onFilter = (event: DataTableFilterEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{filters: event.filters}
        }));
    };


    return (
        <>
            <div className="container">
                <h1>Шаблоны сообщений</h1>

                <DataTable value={messageTemplates} dataKey="id"
                           header={tableHeader} footer={tableFooter}
                           lazy loading={busy} first={tableState.pagination.first} totalRecords={totalRecords}
                           filterDisplay="menu" onFilter={onFilter} filters={tableState.filters} filterLocale="ru"
                           sortField={tableState.sorting.sortField} sortOrder={tableState.sorting.sortOrder}
                           onSort={onSort}
                           paginator rows={tableState.pagination.rows}
                           rowsPerPageOptions={messageTemplateRowsPerPage}
                           onPage={onPage}
                           selectionMode="multiple" selection={selected!}
                           onSelectionChange={e => setSelected(e.value)}
                           scrollable={true} size="small"
                           stateStorage="local" stateKey="nsg-pa-dt-messageTemplates"
                           emptyMessage="Нет данных">

                    <Column selectionMode="multiple"/>
                    <Column body={editButtonTemplate}/>

                    {visibleOnly(visibleFields, messageTemplateColumns)}
                </DataTable>
            </div>


            {/* Диалог регистрации и редактирования списка рассылки */}

            <MessageTemplatePropertiesDialog messageTemplate={dialogState.entity}
                                             mode={dialogState.mode}
                                             visible={dialogState.visible}
                                             onHide={() => setDialogState(prev => ({
                                                 ...prev,
                                                 ...{visible: false}
                                             }))}
                                             onCancel={() => setDialogState(prev => ({
                                                 ...prev,
                                                 ...{visible: false}
                                             }))}
                                             onSave={() => {
                                                 setDialogState(prev => ({
                                                     ...prev,
                                                     ...{visible: false}
                                                 }));
                                                 reload();
                                             }}
            />
        </>
    );
}