import {API_LOG_URL} from "../config";
import {BaseDataService} from "./BaseDataService";
import {LogRecord} from "../interfaces/LogRecord.interface";


export default class LogService extends BaseDataService<LogRecord> {
    constructor() {
        super(API_LOG_URL);
    }
}
export const logService: LogService = new LogService();