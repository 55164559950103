import React from "react";
import {ScrollTop} from "primereact/scrolltop";
import LogTable from "../components/log/LogTable";

export default function LogPage() {
    return (
        <div className="container-fluid">
            <h1>Системный журнал</h1>
            <LogTable/>

            <ScrollTop threshold={100}
                       className="w-2rem h-2rem border-round-md bg-primary"
                       icon="pi pi-arrow-up text-base"/>
        </div>
    );
};

export const MemoizedLogPage = React.memo(LogPage);