import React from "react";
import MediaAssetPanel from "../components/common/mediaAsset/MediaAssetPanel";

export default function MediaAssetsPage() {

    return (
        <div className="container-fluid">
            <MediaAssetPanel mode="viewer"/>
        </div>
    );
}