import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import React, {useState} from "react";
import useUserNotification from "../../../hooks/useUserNotification";
import {errorMessage} from "../../../helpers/axiosError";
import {BlockUI} from "primereact/blockui";
import {useUpdateEffect} from "primereact/hooks";
import SaveButton from "../../../components/common/buttons/SaveButton";
import CancelButton from "../../../components/common/buttons/CancelButton";
import {EntityDialogState} from "../../../components/common/dialog/EntityDialogState";
import {Ticket} from "../../../interfaces/ticket/Ticket";
import TicketStatusDropdown from "../../../components/ticket/TicketStatusDropdown";
import {TicketStatus} from "../../../interfaces/enum/TicketStatus";
import {ticketService} from "../../../service/TicketService";


export interface TicketDialogState extends EntityDialogState<Ticket> {
}

interface Props {
    dialogState: TicketDialogState;

    onHide(): void;

    onCancel(): void;

    onSave(): void;
}

export default function TicketPropertiesDialog(props: Props) {
    const title = props.dialogState.mode === "create" ?
        "Регистрация нового обращения" :
        "Редактирование обращения";

    const [busy, setBusy] =
        useState<boolean>(false);

    const [editedTicket, setEditedTicket] =
        useState<Ticket>(props.dialogState.entity);

    const {showError, showSuccess} = useUserNotification();

    useUpdateEffect(() => {
        setEditedTicket(props.dialogState.entity);
    }, [props.dialogState.entity]);

    const update = () => {
        setBusy(true);

        if (props.dialogState.mode === "create") {
            ticketService.create(editedTicket)
                .then(() => {
                    showSuccess({
                        summary: title,
                        detail: `Обращение успешно зарегистрировано в системе.`
                    });
                    if (props.onSave) props.onSave();

                })
                .catch(reason => {
                    showError({
                        summary: title,
                        detail: errorMessage(reason)
                    });
                })
                .finally(() => {
                    setBusy(false);
                });

        } else if (editedTicket.id) {
            ticketService.update(editedTicket.id, editedTicket)
                .then(() => {
                    showSuccess({
                        summary: title,
                        detail: `Регистрационные данные обращения успешно сохранены.`
                    });
                    if (props.onSave) props.onSave();

                })
                .catch(reason => {
                    showError({
                        summary: title,
                        detail: errorMessage(reason)
                    });
                })
                .finally(() => {
                    setBusy(false);
                })
        }

        props.onSave();
    };

    const footer = (
        <div>
            <SaveButton onClick={update} disabled={busy}/>
            <CancelButton onClick={props.onCancel} disabled={busy}/>
        </div>
    );


    return (
        <BlockUI blocked={busy}>
            <Dialog header={title}
                    visible={props.dialogState.visible}
                    onHide={props.onHide}
                    footer={footer}
                    className="app-dialog">

                <div className="flex flex-column gap-4">
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="topic" className="font-bold">Тема</label>
                        <InputText id="topic" value={editedTicket.topic}
                                   required={true}
                                   onChange={(e) => {
                                       setEditedTicket(editedTicket => ({
                                           ...editedTicket,
                                           ...{name: e.target.value}
                                       }))
                                   }}/>
                    </div>
                    <div className="flex flex-column gap-2 m-0">
                        <label htmlFor="status" className="font-bold">Статус</label>
                        <TicketStatusDropdown id="status"
                                              status={editedTicket.status}
                                              onStatusSelect={(status: TicketStatus) => {
                                                  setEditedTicket(prev => ({
                                                      ...prev,
                                                      ...{status: status}
                                                  }))
                                              }}/>
                    </div>
                    {/*<div className="flex flex-column gap-2 m-0">
                        <label htmlFor="phone" className="font-bold">Телефон</label>
                        <InputPhone id="phone" value={editedTicket.}
                                    required={true}
                                    onChange={(e) => {
                                        setEditedDispatcherConsole(editedTicket => ({
                                            ...editedTicket,
                                            ...{phone: e.target.value ? e.target.value : ''}
                                        }));
                                    }}/>
                    </div>*/}
                </div>
            </Dialog>
        </BlockUI>
    );
}