import {BaseDataService} from "./BaseDataService";
import {API_TAXONOMY_URL} from "../config";
import Taxonomy from "../interfaces/Taxonomy";

export default class TaxonomyService extends BaseDataService<Taxonomy> {

    constructor() {
        super(API_TAXONOMY_URL);
    }
}
export const taxonomyService: TaxonomyService = new TaxonomyService();