import {Dropdown, DropdownChangeEvent, DropdownProps} from "primereact/dropdown";
import React, {useState} from "react";
import useUserNotification from "../hooks/useUserNotification";
import ApiResponse from "../interfaces/response.interface";
import {errorMessage} from "../helpers/axiosError";
import {useMountEffect} from "primereact/hooks";
import Organization from "../interfaces/Organization";
import OrganizationOutput from "./OrganizationOutput";
import {organizationService} from "../service/OrganizationService";

interface OrganizationDropdownProps {
    id?: string | undefined;
    value: number | undefined;

    onChange(id: number | undefined): void;
}

export default function OrganizationDropdown(props: OrganizationDropdownProps) {

    const [busy, setBusy] =
        useState<boolean>(false);

    const [organizations, setOrganizations] =
        useState<Organization[]>([]);

    const {showError} = useUserNotification();

    const reload = () => {
        setBusy(true);

        organizationService.findAll()
            .then((response: ApiResponse<Organization>) => {
                setOrganizations(response.content);
            })
            .catch(reason => {
                setOrganizations([]);
                showError({
                    summary: "Загрузка организаций",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => {
                setBusy(false);
            });
    };

    useMountEffect(() => {
        reload();
    });

    const itemTemplate = (organization: Organization) => {
        return <OrganizationOutput organization={organization}/>;
    }

    const valueTemplate = (organization: Organization, props: DropdownProps) => {
        if (organization) {
            return <OrganizationOutput organization={organization}/>;
        }
        return <>{props.placeholder}</>;
    }


    return (
        <Dropdown id={props.id}
                  value={props.value}
                  valueTemplate={valueTemplate}
                  itemTemplate={itemTemplate}
                  loading={busy}
                  options={organizations}
                  optionValue="id"
                  optionLabel="shortTitle"
                  showClear
                  filter
                  filterBy="shortTitle"
                  onChange={(e: DropdownChangeEvent) =>
                      props.onChange(e.target.value ? parseInt(e.target.value) : undefined)
                  }
                  emptyMessage="Нет зарегистрированных организаций"
                  placeholder="Выберите организацию"/>
    );
}