import {Tag} from "primereact/tag";
import {useMountEffect, useUpdateEffect} from "primereact/hooks";
import {useState} from "react";
import Taxonomy from "../../../interfaces/Taxonomy";
import {errorMessage} from "../../../helpers/axiosError";
import {taxonomyService} from "../../../service/TaxonomyService";

interface TaxonomyTermsOutputProps {
    taxonomyIds: number[] | undefined | null;
}

export default function TaxonomyTagsLoader(props: TaxonomyTermsOutputProps) {

    const [taxonomies, setTaxonomies] =
        useState<Taxonomy[]>([]);

    const reload = () => {
        if (props.taxonomyIds) {
            taxonomyService
                .in(props.taxonomyIds)
                .then(response => setTaxonomies(response.content))
                .catch(reason => {
                    console.error(errorMessage(reason));
                    setTaxonomies([]);
                });
        }
    };

    useMountEffect(() => reload());
    useUpdateEffect(() => reload(), [props.taxonomyIds]);

    return (
        <>
            {taxonomies.map((t, index) =>
                <Tag key={index} value={t.name} className="mr-1 mb-1"/>)
            }
        </>
    );
}