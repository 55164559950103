import {useState} from "react";
import {TreeSelect, TreeSelectChangeEvent} from "primereact/treeselect";
import {useMountEffect} from "primereact/hooks";
import {TreeNode} from "primereact/treenode";
import {Paging} from "../../service/BaseDataService";
import {MAX_INT} from "../../config";
import ApiResponse from "../../interfaces/response.interface";
import Taxonomy from "../../interfaces/Taxonomy";
import toTreeNodes from "../../helpers/toTreeNodes";
import {errorMessage} from "../../helpers/axiosError";
import {useAppSettings} from "../../providers/AppSettingsProvider";
import {taxonomyService} from "../../service/TaxonomyService";


export interface TicketTaxonomySelectProps {
    id?: string | undefined;
    value: number | null | undefined;

    onChange(id: number | null): void;
}

export default function TicketTaxonomySelect(props: TicketTaxonomySelectProps) {

    const {appSettings} = useAppSettings();

    const [busy, setBusy] =
        useState<boolean>(false);

    const [treeNodes, setTreeNodes] =
        useState<TreeNode[]>([]);

    const reload = () => {
        if (!appSettings.ticketServiceTopicTaxonomy) return;

        setBusy(true);

        const paging: Paging = {
            page: 0,
            rows: MAX_INT
        };
        const parentId: number = appSettings.ticketServiceTopicTaxonomy;
        const filter: string = `filter=parent.id:${parentId}`;

        taxonomyService.findAll(filter, paging)
            .then((response: ApiResponse<Taxonomy>) => {
                const treeNodes: TreeNode[] = [];
                response.content?.forEach(t => t.parent = undefined);
                toTreeNodes(response.content, treeNodes, null)
                setTreeNodes(treeNodes);

            })
            .catch(reason => {
                console.error(errorMessage(reason));
                setTreeNodes([]);
            })
            .finally(() => {
                setBusy(false);
            });
    };

    useMountEffect(() => reload());

    return <TreeSelect id={props.id}
                       options={treeNodes}
                       value={props.value?.toString()}
                       placeholder="Выберите тему обращения"
                       className="w-full"
                       disabled={busy}
                       onChange={(e: TreeSelectChangeEvent) => {
                           props.onChange(e.value ? parseInt(e.value.toString()) : null)
                       }}/>;
}