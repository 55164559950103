import {API_DISPATCHER_CONSOLE_URL} from "../config";
import {BaseDataService} from "./BaseDataService";
import DispatcherConsole from "../interfaces/DispatcherConsole.interface";

export default class DispatcherConsoleService extends BaseDataService<DispatcherConsole> {

    constructor() {
        super(API_DISPATCHER_CONSOLE_URL);
    }
};
export const dispatcherConsoleService: DispatcherConsoleService = new DispatcherConsoleService();