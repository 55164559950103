import {MediaAsset} from "../../../interfaces/MediaAsset";
import React, {useCallback, useState} from "react";
import {errorMessage} from "../../../helpers/axiosError";
import {useMountEffect, useUpdateEffect} from "primereact/hooks";
import {TicketMessage} from "../../../interfaces/ticket/TicketMessage";
import {TicketMessageDto} from "../../../interfaces/ticket/TicketMessageDto";
import MediaAssetDownloadLink from "../../../components/common/mediaAsset/MediaAssetDownloadLink";
import {ticketMessageService} from "../../../service/TicketMessageService";

interface TicketMessagesAssetsOutputProps {
    ticketMessage: TicketMessage | TicketMessageDto;
}

export default function TicketMessagesAssetsOutput(props: TicketMessagesAssetsOutputProps) {

    const [assets, setAssets] =
        useState<MediaAsset[] | undefined>(undefined);

    const reload = () => {
        if (props.ticketMessage?.id) {
            ticketMessageService.findAssets(props.ticketMessage.id)
                .then(response => {
                    setAssets(response);
                })
                .catch(reason => console.error(errorMessage(reason)));
        }
    };
    const reloadCallback = useCallback(reload, [props.ticketMessage]);

    useMountEffect(() => {
        reloadCallback();
    });

    useUpdateEffect(() => {
        reloadCallback()
    }, [props.ticketMessage]);

    return (
        <div className="flex flew-row flex-wrap gap-2">{
            assets?.map((asset, index) => {
                return <MediaAssetDownloadLink key={index} asset={asset}/>
            })
        }</div>
    );
}