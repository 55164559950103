import {API_MAIL_URL} from "../config";
import axios from "axios";

export default class MailService {
    url: string = API_MAIL_URL;

    public async send(to: string, text: string): Promise<any> {
        const request = encodeURI(`${this.url}/send?to=${to}&text=${text}`);
        return await axios.put(request);
    }
}
export const mailService: MailService = new MailService();