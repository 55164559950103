import {
    DataTable,
    DataTableDataSelectableEvent,
    DataTableFilterEvent,
    DataTablePageEvent,
    DataTableSortEvent
} from "primereact/datatable";
import {Column} from "primereact/column";
import React, {useRef, useState} from "react";
import {Button} from "primereact/button";
import {initialMediaAsset, MediaAsset} from "../../../interfaces/MediaAsset";
import useUserNotification from "../../../hooks/useUserNotification";
import {useMountEffect, useStorage, useUpdateEffect} from "primereact/hooks";
import ApiResponse, {ApiErrorResponse} from "../../../interfaces/response.interface";
import {errorMessage} from "../../../helpers/axiosError";
import {confirmDialog} from "primereact/confirmdialog";
import TableState from "../../../interfaces/TableState";
import {API_MEDIA_ASSETS_URL, LS_KEY, LS_TOKEN_KEY} from "../../../config";
import {
    mediaAssetPanelPaginationInitialState,
    mediaAssetPanelRowsPerPage,
    mediaAssetPanelSortingInitialState
} from "./MediaAssetPanelConstants";
import {mediaAssetPanelFilterInitialState} from "./mediaAssetPanelFilterInitialState";
import MediaAssetPropertiesDialog, {MediaAssetPropertiesDialogState} from "./MediaAssetPropertiesDialog";
import tableStateToPage from "../dataTable/tableStateToPage";
import tableStateToSorting from "../dataTable/tableStateToSorting";
import {pfsFilterToSpring} from "../../../helpers/primereact-turkraft";
import {initialMediaAssetColumns, mediaAssetPanelColumns} from "./MediaAssetPanelColumns";
import CreateButton from "../buttons/CreateButton";
import DeleteButton from "../buttons/DeleteButton";
import ColumnToggle, {visibleOnly} from "../dataTable/ColumnToggle";
import FilterResetButton from "../buttons/FilterResetButton";
import EditButton from "../buttons/EditButton";
import ReloadButton from "../buttons/ReloadButton";
import UploadButton from "../buttons/UploadButton";
import {OverlayPanel} from "primereact/overlaypanel";
import {FileUpload, FileUploadBeforeUploadEvent} from "primereact/fileupload";
import {AxiosError} from "axios";
import DownloadButton from "../buttons/DownloadButton";
import {useAuth} from "../../../security/AuthProvider";
import {mediaAssetService} from "../../../service/MediaAssetService";


export type MediaAssetPanelMode = "chooser" | "viewer";

interface Props {
    mode: MediaAssetPanelMode;
    notSelectable?: MediaAsset[] | undefined;
    showEdit?: boolean | undefined;
    showUpload?: boolean | undefined;
    showDownload?: boolean | undefined;

    onSelect?(asset: MediaAsset): void;
}


export default function MediaAssetPanel(props: Props) {

    const ls_columns_key = props.mode === "chooser" ?
        LS_KEY + "-dt-media-assets-chooser-cols" : LS_KEY + "-pa-dt-media-assets-viewer-cols";

    const ls_filter_key = props.mode === "chooser" ?
        LS_KEY + "-dt-media-assets-chooser-filter" : LS_KEY + "-dt-media-assets-viewer-filter";


    const {showError, showSuccess} = useUserNotification();
    const {isAdmin, isUserManagement} = useAuth();


    const [busy, setBusy] = useState(false);

    // const [layout, setLayout] = useState<any>('grid');

    const [tableState, setTableState] =
        useState<TableState>({
            filters: mediaAssetPanelFilterInitialState,
            pagination: mediaAssetPanelPaginationInitialState,
            sorting: mediaAssetPanelSortingInitialState
        });

    const [dialogState, setDialogState] =
        useState<MediaAssetPropertiesDialogState>({
            mode: "edit",
            entity: {...initialMediaAsset},
            visible: false
        });

    const [assets, setAssets] =
        useState<MediaAsset[]>([]);

    const [totalRecords, setTotalRecords] =
        useState<number>(0);

    const [selected, setSelected] =
        useState<MediaAsset[]>([]);

    const [visibleFields, setVisibleFields] =
        useStorage<string[]>(initialMediaAssetColumns(), ls_columns_key);

    const reload = () => {
        setBusy(true);

        const paging = tableStateToPage(tableState);
        const sorting = tableStateToSorting(tableState);
        const filter = pfsFilterToSpring(tableState.filters);

        mediaAssetService.findAll(filter, paging, sorting)
            .then((response: ApiResponse<MediaAsset>) => {
                setAssets(response.content);
                setTotalRecords(response.totalElements);

            })
            .catch(reason => {
                setAssets([]);
                setTotalRecords(0);
                showError({
                    summary: "Загрузка файлов",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => {
                setBusy(false);
                setSelected([]);
            });
    };

    useMountEffect(() => reload());
    useUpdateEffect(() => reload(), [tableState]);


    const initFilters = () => {
        setTableState(prevState => ({
            ...prevState,
            ...{filters: mediaAssetPanelFilterInitialState}
        }));
    };

    const hasSelected = () => {
        return selected && selected.length > 0;
    };

    const isSelectable = (asset: MediaAsset) => {
        if (props.notSelectable && props.notSelectable.length > 0)
            return !props.notSelectable.some((ns: MediaAsset) => ns.id === asset.id);
        return true;
    };

    const isRowSelectable = (event: DataTableDataSelectableEvent) => {
        return event.data ? isSelectable(event.data as MediaAsset) : true;
    };

    const rowClassName = (data: MediaAsset) => (isSelectable(data) ? '' : 'p-disabled');

    const deleteFiles = () => {
        if (hasSelected()) {
            setBusy(true);

            mediaAssetService.deleteAll(selected).then(() => {
                showSuccess({
                    summary: "Удаление файлов",
                    detail: "Файлы успешно удалены."
                });
            }).catch((reason: AxiosError<ApiErrorResponse>) => {
                showError({
                    summary: "Удаление файлов",
                    detail: errorMessage(reason)
                });
            }).finally(() => {
                setSelected([]);
                reload();
            });
        }
    };

    const op = useRef<any>(null);
    const [uploadingAsset, setUploadingAsset] =
        useState<MediaAsset | undefined>(undefined);

    const assetActions = (asset: MediaAsset) => {
        return (
            <div className="flex flex-row flex-nowrap gap-1">
                {(props.showEdit === undefined || props.showEdit) &&
                    <EditButton id="edit-asset-action"
                                onClick={() => {
                                    setDialogState({
                                        mode: "edit",
                                        entity: {...asset},
                                        visible: true
                                    });
                                }} tooltip="Редактировать карточку файла"
                                text
                    />
                }
                {(props.showUpload === undefined || props.showUpload) &&
                    <UploadButton id="upload-file-to-asset-action"
                                  onClick={(e) => {
                                      setUploadingAsset(asset);
                                      op.current?.toggle(e);
                                  }}
                                  text
                    />
                }
                {(props.showDownload === undefined || props.showDownload) &&
                    <DownloadButton id="download-asset-file-action"
                                    onClick={() => download(asset)}
                                    text
                    />
                }
            </div>
        );
    };


    const selectAssetButtonTemplate = (asset: MediaAsset) => {
        return <Button onClick={() => (props.onSelect) && props.onSelect(asset)}
                       icon="pi pi-external-link"
                       tooltip="Выбрать"
        />
    };

    const deleteConfirmation = () => {
        confirmDialog({
            message: 'Вы действительно хотите удалить выбранные файлы?',
            header: 'Удаление файлов',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: "Да",
            rejectLabel: "Нет",
            style: {width: '50vw'},
            accept() {
                deleteFiles();
            }
        });
    };


    /*
        Обработчики событий
    */

    const onPage = (event: DataTablePageEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{pagination: event}
        }));
    };

    const onSort = (event: DataTableSortEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{sorting: event}
        }));
    };

    const onFilter = (event: DataTableFilterEvent) => {
        setTableState(prevState => ({
            ...prevState,
            ...{filters: event.filters}
        }));
    };

    const onBeforeUpload = (e: FileUploadBeforeUploadEvent) => {
        const token = localStorage.getItem(LS_TOKEN_KEY);
        e.xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    };

    const onUpload = () => {
        op.current?.hide();
        showSuccess({
            summary: "Загрузка файла",
            detail: "Файл успешно загружен."
        });
        reload();
    };

    const onDownloadSelected = () => {
        if (hasSelected()) {
            // todo Скачивание несколько файлов одним архивом
            const asset = selected[0];
            mediaAssetService.downloadAsset(asset).then()
                .catch(() => showError({
                    summary: "Скачивание файла",
                    detail: "Системная ошибка, пожалуйста обратитесь в техническую поддержку."
                }));
        }
    };

    const download = (asset: MediaAsset) => {
        mediaAssetService.downloadAsset(asset).then()
            .catch(() => showError({
                summary: "Скачивание файла",
                detail: "Системная ошибка, пожалуйста обратитесь в техническую поддержку."
            }));
    };

    /* Шапка и подвал таблицы */

    const tableHeader = (
        <div className="flex flex-row gap-4 justify-content-between">
            <div className="flex flex-row gap-1">
                <ReloadButton onClick={reload}/>
                <FilterResetButton onClick={initFilters}/>
                <CreateButton tooltip="Зарегистрировать новый файл"
                              onClick={() => {
                                  setDialogState({
                                      mode: "create",
                                      entity: {...initialMediaAsset},
                                      visible: true
                                  });
                              }}
                              disabled={!selected}
                              className="ml-1"/>
                {isAdmin() &&
                    <DeleteButton onClick={deleteConfirmation}
                                  disabled={!hasSelected() || isUserManagement()}
                                  tooltip="Удалить файл"/>
                }
                <DownloadButton onClick={onDownloadSelected}
                                disabled={!hasSelected()}/>
                <Button type="button"
                        icon="pi pi-fw pi-upload"
                        tooltip="Загрузить файл на сервер"
                        onClick={(e) => {
                            setUploadingAsset(selected[0]);
                            op.current?.toggle(e);
                        }}
                        disabled={!hasSelected() || selected.length > 1}
                />
            </div>
            <div className="flex flex-row gap-1">
                <ColumnToggle columns={mediaAssetPanelColumns}
                              visibleFields={visibleFields}
                              onColumnToggle={fields => setVisibleFields(fields)}/>
            </div>
        </div>
    );

    const tableFooter = `Всего: ${totalRecords}`;

    return (
        <>
            <DataTable value={assets} dataKey="id"
                       header={tableHeader} footer={tableFooter}
                       lazy loading={busy} first={tableState.pagination.first} totalRecords={totalRecords}
                       filterDisplay="menu" onFilter={onFilter} filters={tableState.filters} filterLocale="ru"
                       sortField={tableState.sorting.sortField} sortOrder={tableState.sorting.sortOrder} onSort={onSort}
                       paginator rows={tableState.pagination.rows}
                       rowsPerPageOptions={mediaAssetPanelRowsPerPage}
                       onPage={onPage}
                       selectionMode="checkbox" selection={selected!}
                       onSelectionChange={e => setSelected(e.value)}
                       isDataSelectable={isRowSelectable} rowClassName={rowClassName}
                       scrollable={true} size="small"
                       stateStorage="local" stateKey={ls_filter_key}
                       emptyMessage="Нет данных">

                <Column hidden={props.mode === "viewer"}
                        body={selectAssetButtonTemplate}
                        headerStyle={{width: '5rem'}}/>

                <Column hidden={props.mode === "chooser"}
                        selectionMode="multiple"/>

                <Column hidden={props.mode === "chooser"}
                        body={assetActions}/>

                {visibleOnly(visibleFields, mediaAssetPanelColumns)}
            </DataTable>


            {/* Диалог регистрации нового или редактирования свойств существующего медиа-актива */}

            <MediaAssetPropertiesDialog asset={dialogState.entity}
                                        mode={dialogState.mode}
                                        visible={dialogState.visible}
                                        onHide={() => setDialogState(prev => ({
                                            ...prev,
                                            ...{visible: false}
                                        }))}
                                        onCancel={() => setDialogState(prev => ({
                                            ...prev,
                                            ...{visible: false}
                                        }))}
                                        onSave={() => {
                                            setDialogState(prev => ({
                                                ...prev,
                                                ...{visible: false}
                                            }));
                                            reload();
                                        }}
            />


            <OverlayPanel ref={op}>
                <p>Выберите файл для загрузки взамен имеющегося</p>
                <FileUpload id="file"
                            mode="basic"
                            auto
                            disabled={!uploadingAsset}
                            name="file"
                            onBeforeSend={onBeforeUpload}
                            url={`${API_MEDIA_ASSETS_URL}/update/${uploadingAsset?.id}/file`}
                            maxFileSize={134217728}
                            onUpload={onUpload}
                />
            </OverlayPanel>
        </>
    );
};