import {errorMessage} from "../../../helpers/axiosError";
import React from "react";
import {useMountEffect, useUpdateEffect} from "primereact/hooks";
import NotificationRecipientSpecial from "../../../interfaces/notification/NotificationRecipientSpecial";
import {notificationRecipientSpecialService} from "../../../service/notification/NotificationRecipientSpecialService";

interface NotificationRecipientOutputProps {
    recipientId: number | undefined | null;
}

export type Props = NotificationRecipientOutputProps;


export default function NotificationRecipientOutput(props: NotificationRecipientOutputProps) {

    const [recipient, setRecipient] =
        React.useState<NotificationRecipientSpecial | undefined>(undefined);


    const reload = () => {
        if (props.recipientId) {
            notificationRecipientSpecialService.find(props.recipientId)
                .then(response => {
                    setRecipient(response);
                })
                .catch(reason => {
                    console.error(errorMessage(reason));
                });
        }
    };

    useMountEffect(() => reload());
    useUpdateEffect(() => reload(), [props.recipientId]);

    if (recipient) {
        return <>{recipient.address}</>;
    }
    return <></>;
}