import axios, {AxiosResponse} from "axios";
import ApiResponse from "../interfaces/response.interface";
import AbstractEntity from "../interfaces/AbstractEntity";
import {DeleteResponse} from "../interfaces/DeleteResponse";
import idToString from "../helpers/entitiesHelper";
import {MAX_INT} from "../config";

export interface Paging {
    page: number;
    rows: number;
}

export interface Sorting {
    field: string;
    order?: SortOrder;
}

export type SortOrder = "asc" | "desc";

export class BaseDataService<T extends AbstractEntity> {

    url: string = "";

    constructor(api: string) {
        this.url = api;
    }

    async findAll(filter?: string, paging?: Paging, sorting?: Sorting): Promise<ApiResponse<T>> {
        const _paging: string = this._paging(paging);
        const _sorting: string | undefined = this._sorting(sorting);
        const params: string = [filter, _paging, _sorting]
            .filter((param: string | undefined): boolean => param !== undefined)
            .join("&");
        const request: string = this.url + "?" + encodeURI(params);
        //console.log(request);
        const response: AxiosResponse<ApiResponse<T>> = await axios.get(request);
        return response.data;
    }

    async find(id: number): Promise<T> {
        const response: AxiosResponse<T> = await axios.get<T>(this.url + "/" + id);
        return response.data;
    }

    async in(ids: number[], paging?: Paging, sorting?: Sorting): Promise<ApiResponse<T>> {
        const filter: string = `filter=id in [${ids.join(",")}]`;
        return this.findAll(filter, paging, sorting);
    }

    async create(entity: T): Promise<AxiosResponse<T>> {
        return await axios.post<T>(this.url, entity);
    }

    async update(id: number, entity: T): Promise<AxiosResponse<T>> {
        return await axios.put<T>(this.url + "/" + id, entity);
    }

    async delete(entity: T): Promise<AxiosResponse<DeleteResponse>> {
        return await axios.delete<DeleteResponse>(this.url + "/" + entity.id);
    }

    async deleteAll(entities: T[]): Promise<AxiosResponse<DeleteResponse>> {
        return await axios.delete<DeleteResponse>(this.url + "/" + idToString(entities));
    }

    async deleteById(id: number): Promise<AxiosResponse<DeleteResponse>> {
        return await axios.delete<DeleteResponse>(this.url + "/" + id);
    }

    async deleteByIds(ids: number[]): Promise<AxiosResponse<DeleteResponse>> {
        return await axios.delete<DeleteResponse>(this.url + "/" + ids.join(","));
    }

    async count(): Promise<AxiosResponse<number>> {
        return await axios.get<number>(this.url + "/count");
    }

    async countBy(filter: string): Promise<AxiosResponse<number>> {
        let request = this.url + "/count/by?filter=" + filter;
        return await axios.get<number>(request);
    }

    _paging(paging: Paging | undefined | null): string {
        if (paging) {
            return `page=${paging.page}&size=${paging.rows}`;
        } else {
            return `page=0&size=${MAX_INT}`;
        }
    }

    _sorting(sorting?: Sorting): undefined | string {
        if (sorting) {
            if (sorting.order) {
                return `sort=${sorting.field},${sorting.order}`;
            } else {
                return `sort=${sorting.field}`;
            }
        }
        return `sort=id,asc`;
    }
}