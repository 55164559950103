import React from "react";
import {Link} from "react-router-dom";
import {errorMessage} from "../helpers/axiosError";
import useUserNotification from "../hooks/useUserNotification";
import {useAppSettings} from "../providers/AppSettingsProvider";
import UserProfile from "../interfaces/UserProfile.interface";
import {documentTemplateService} from "../service/DocumentTemplateService";
import {fullName} from "../helpers/functions";

interface InsurancePolicyLinkProps {
    profile?: UserProfile | undefined | null;
}

export type Props = InsurancePolicyLinkProps;

export default function InsurancePolicyLink(props: Props) {
    const {profile} = props;
    const {appSettings} = useAppSettings();
    const {showError} = useUserNotification();

    const downloadHandler = () => {
        if (profile && profile.id) {
            documentTemplateService
                .substituteInsurancePolicyDownloadByProfile(
                    profile.id,
                    appSettings.insurancePolicyFilename
                )
                .then()
                .catch(reason => {
                    console.error(reason);
                    showError({
                        summary: "Загрузка страхового полиса",
                        detail: "Системная ошибка, обратитесь пожалуйста в техническую поддержку. "
                            + errorMessage(reason)
                    });
                });
        }
    };

    if (profile && profile.insurancePolicyNumber && appSettings.insurancePolicyFilename) {
        return (
            <Link to={""}
                  onClick={downloadHandler}
                  title={`Скачать страховой полис ${fullName(profile)}`}><i
                className="pi pi-paperclip"></i> {profile.insurancePolicyNumber}
            </Link>
        );
    }

    return null;
}