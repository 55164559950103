import {BaseDataService} from "./BaseDataService";
import {API_MESSAGE_TEMPLATE_URL} from "../config";
import MessageTemplate from "../interfaces/notification/MessageTemplate";

export default class MessageTemplateService extends BaseDataService<MessageTemplate> {

    constructor() {
        super(API_MESSAGE_TEMPLATE_URL);
    }
}
export const messageTemplateService: MessageTemplateService = new MessageTemplateService();