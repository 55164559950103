import React, {createContext, useContext, useEffect, useState} from "react";
import JwtAuthenticationResponse from "../interfaces/auth/JwtAuthenticationResponse";
import {anonymousUser, anonymousUserProfile, LS_USER_KEY, LS_USER_PROFILE_KEY,} from "../config";
import useUserNotification from "../hooks/useUserNotification";
import {AxiosError} from "axios";
import User from "../interfaces/User.interface";
import UserProfile from "../interfaces/UserProfile.interface";
import {useNavigate} from "react-router-dom";
import {
    isAdminUser,
    isAnonymousUser,
    isInsuredUser,
    isRegisteredUser,
    isTherapistUser,
    isUserManagementUser
} from "../helpers/authFunctions";
import {authService} from "../service/AuthService";

// @see https://medium.com/@ghimiresamana666/authentication-and-authorization-in-react-8fc76a496ba0
// @see https://codesandbox.io/s/jovial-glade-xjh7hb?file=/src/provider/AuthProvider.js


export type AuthProviderContextType = {
    user: User;
    userProfile: UserProfile;
    isAnonymous(): boolean;
    isRegistered(): boolean;
    isInsured(): boolean;
    isTherapist(): boolean;
    isUserManagement(): boolean;
    isAdmin(): boolean;
    login(login: string, password: string): void,
    logout(): void
};

const AuthContext: React.Context<AuthProviderContextType> = createContext<AuthProviderContextType>(
    {
        user: anonymousUser,
        userProfile: anonymousUserProfile,
        isAnonymous(): boolean {
            return false;
        },
        isRegistered(): boolean {
            return false;
        },
        isInsured(): boolean {
            return false;
        },
        isTherapist(): boolean {
            return false;
        },
        isUserManagement(): boolean {
            return false;
        },
        isAdmin(): boolean {
            return false;
        },
        login(login: string, password: string): void {
        },
        logout(): void {
        }
    }
);

export const AuthProvider = ({children}: any) => {

    const userString = localStorage.getItem(LS_USER_KEY);
    const userProfileString = localStorage.getItem(LS_USER_PROFILE_KEY);

    const {showError} = useUserNotification();
    const navigate = useNavigate();

    const [user, setUser] =
        useState<User>(userString ? JSON.parse(userString) : anonymousUser);
    const [userProfile, setUserProfile] =
        useState<UserProfile>(userProfileString ? JSON.parse(userProfileString) : anonymousUserProfile);

    useEffect(() => {
        authService.reload()?.then(u => {
            // user.current = u;
            // userProfile.current = u.profile;
            setUser(u);
            setUserProfile(u.profile);
        });
    }, []);


    const ctxIsAnonymous = (): boolean => {
        return isAnonymousUser(user);
        // return !user.current || user.current === anonymousUser || userHasRole(ROLE_ANONYMOUS);
        //return !user || user === anonymousUser || userHasRole(user, ROLE_ANONYMOUS);
    };

    const ctxIsRegistered = (): boolean => {
        //return userHasRole(user, ROLE_REGISTERED_USER);
        return isRegisteredUser(user);
    }

    const ctxIsTherapist = (): boolean => {
        // return userHasRole(user, ROLE_THERAPIST);
        return isTherapistUser(user);
    };

    const ctxIsUserManagement = (): boolean => {
        // return userHasRole(user, ROLE_USER_MANAGEMENT);
        return isUserManagementUser(user);
    };

    const ctxIsAdmin = (): boolean => {
        // return userHasRole(user, ROLE_ADMINISTRATOR);
        return isAdminUser(user);
    };

    const ctxIsInsured = (): boolean => {
        // return userHasRole(user, ROLE_INSURED);
        return isInsuredUser(user);
    }

    const ctxLogin = (login: string, password: string): string => {
        let message: string = "";

        try {
            authService
                .login({login: login, password: password})
                .then((response: JwtAuthenticationResponse) => {
                    authService.jwtProcess(response.accessToken)?.then((user: User) => {
                        setUser(user);
                        setUserProfile(user.profile);
                        navigate("/");
                    });

                })
                .catch((reason: AxiosError) => {
                    if (reason.response?.status === 401) {
                        showError({
                            summary: "Авторизация",
                            detail: "Логин, или пароль введены некорректно, либо пользователь не зарегистрирован."
                        });
                    } else {
                        showError({
                            summary: "Системная ошибка",
                            detail: reason.message
                        });
                    }
                    return reason;
                });

        } catch (e: unknown) {
            if (e instanceof Error) message = e.message;
            else String(e);
        }

        return message;
    }

    const ctxLogout = () => {
        authService.logout();
    }

    return (
        <AuthContext.Provider
            value={{
                // user: user.current,
                // userProfile: userProfile.current,
                user: user,
                userProfile: userProfile,
                isAnonymous: ctxIsAnonymous,
                isRegistered: ctxIsRegistered,
                isInsured: ctxIsInsured,
                isTherapist: ctxIsTherapist,
                isUserManagement: ctxIsUserManagement,
                isAdmin: ctxIsAdmin,
                login: ctxLogin,
                logout: ctxLogout
            }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth: () => AuthProviderContextType = () => {
    return useContext(AuthContext);
}