import {BaseDataService} from "./BaseDataService";
import Organization from "../interfaces/Organization";
import {API_ORGANIZATIONS_URL} from "../config";

export default class OrganizationService extends BaseDataService<Organization> {

    constructor() {
        super(API_ORGANIZATIONS_URL);
    }
}
export const organizationService: OrganizationService = new OrganizationService();