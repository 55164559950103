import ColumnMeta from "../../interfaces/ColumnMeta";
import {Column} from "primereact/column";
import {
    dateTimeRangeFilterTemplate,
    numberFilterTemplate,
    textFilterTemplate
} from "../../components/common/dataTable/filterTemplates";
import React from "react";
import {findRoleType} from "../../config";
import UserFullName from "../../components/UserFullName";
import User from "../../interfaces/User.interface";
import {dictInsurancePrograms, dictOrganizations, dictTherapists, findDictValue} from "../../App";
import {ldf, ldtf} from "../../helpers/dateTime";
import {formatPhone} from "../../helpers/phone";
import OrganizationOutput from "../../components/OrganizationOutput";
import UserStatusOutput from "../../components/UserStatusOutput";
import UserStatusFilter from "../../components/common/dataTable/filter/UserStatusFilter";
import TherapistFilter from "../../components/common/dataTable/filter/TherapistFilter";
import OrganizationFilter from "../../components/common/dataTable/filter/OrganizationFilter";
import InsuranceProgramFilter from "../../components/common/dataTable/filter/InsuranceProgramFilter";
import RoleFilter from "../../components/common/dataTable/filter/RoleFilter";

export function initialUserColumns() {
    return usersPageColumns
        .filter(column => !column.hidden)
        .map(column => column.field)
}

const therapistTemplate = (user: User) => {
    let therapistProfile =
        findDictValue(dictTherapists, user.profile.therapist);
    if (therapistProfile) {
        return <UserFullName userProfile={therapistProfile}/>;
    }
    return <span>Не назначен</span>
};

const insuranceProgramTemplate = (user: User) => {
    const insuranceProgram =
        findDictValue(dictInsurancePrograms, user.profile.insuranceProgram);
    if (insuranceProgram) return <>{insuranceProgram.name}</>
    return <>Не назначена</>
};

/*const serviceProgramTemplate = (user: User) => {
    let serviceProgram =
        findDictValue(dictServicePrograms, user.profile.serviceProgram);
    if (serviceProgram) return <>{serviceProgram.name}</>
    return <>Не назначена</>
};*/

const roleTemplate = (user: User) => {
    return <div className="dict-items">{user.roles.map((roleId: number, index: number) => {
        const title = findRoleType(roleId)?.title;
        return <span key={index} className="dict-item">{title}</span>
    })}</div>;
};

const organizationTemplate = (user: User) => {
    let organization = findDictValue(dictOrganizations, user.profile.organization);
    if (organization) return <OrganizationOutput organization={organization}/>;
    return <>Не назначена</>
};

export const usersPageColumns: ColumnMeta[] = [
    {
        field: 'profile.id',
        header: 'УН',
        hidden: true,
        // column: columnId
        column: <Column key="profile.id" field="profile.id" header="УН" sortable dataType="numeric"
                        body={user => <>{user.profile.id}</>}
                        filter filterElement={numberFilterTemplate}
        />
    },
    {
        field: 'profile.created',
        header: 'Создан',
        hidden: true,
        column: <Column key="profile.created" field="profile.created" header="Создан" sortable
                        dataType="date" body={user => ldtf(user.profile.created)}
                        filter filterElement={dateTimeRangeFilterTemplate}
                        showFilterMenu={true}
                        showFilterMatchModes={false}
                        showFilterOperator={false}
                        showFilterMenuOptions={false}
        />
    },
    {
        field: 'profile.updated',
        header: 'Обновлен',
        hidden: true,
        column: <Column key="profile.updated" field="profile.updated" header="Обновлен" sortable
                        dataType="date" body={user => ldtf(user.profile.updated)}
                        filter filterElement={dateTimeRangeFilterTemplate}
                        showFilterMenu={true}
                        showFilterMatchModes={false}
                        showFilterOperator={false}
                        showFilterMenuOptions={false}
        />
    },
    {
        field: 'registered',
        header: 'Зарегистрирован',
        hidden: true,
        column: <Column key="registered" field="registered" header="Зарегистрирован" sortable
                        dataType="date" body={user => ldtf(user.registered)}
                        filter filterElement={dateTimeRangeFilterTemplate}
                        showFilterMenu={true}
                        showFilterMatchModes={false}
                        showFilterOperator={false}
                        showFilterMenuOptions={false}
        />
    },
    {
        field: 'lastLogin',
        header: 'Последний вход',
        hidden: true,
        column: <Column key="lastLogin" field="lastLogin" header="Последний вход" sortable
                        dataType="date" body={user => ldtf(user.lastLogin)}
                        filter filterElement={dateTimeRangeFilterTemplate}
                        showFilterMenu={true}
                        showFilterMatchModes={false}
                        showFilterOperator={false}
                        showFilterMenuOptions={false}
        />
    },
    {
        field: "username",
        header: "Пользователь",
        hidden: true,
        column: <Column key="username" field="username" header="Пользователь" sortable
                        dataType="text" body={user => <>{user.username}</>}
                        filter filterElement={textFilterTemplate}
        />
    },
    {
        field: "ipAddress",
        header: "IP",
        hidden: true,
        column: <Column key="ipAddress" field="ipAddress" header="IP" sortable
                        dataType="text" body={user => <>{user.ipAddress}</>}
                        filter filterElement={textFilterTemplate}
        />
    },
    {
        field: "profile.secondName",
        header: "Фамилия",
        hidden: false,
        column: <Column key="profile.secondName" field="profile.secondName" sortable
                        header="Фамилия"
                        filter filterElement={textFilterTemplate}
        />
    },
    {
        field: "profile.firstName",
        header: "Имя",
        hidden: false,
        column: <Column key="profile.firstName" field="profile.firstName" sortable
                        header="Имя"
                        filter filterElement={textFilterTemplate}
        />
    },
    {
        field: "profile.thirdName",
        header: "Отчество",
        hidden: false,
        column: <Column key="profile.thirdName" field="profile.thirdName" sortable
                        header="Отчество"
                        filter filterElement={textFilterTemplate}
        />
    },
    {
        field: 'user.profile.dateOfBirth',
        header: 'Дата рождения',
        hidden: true,
        column: <Column key="user.profile.dateOfBirth" field="user.profile.dateOfBirth" header="Дата рождения" sortable
                        dataType="date" body={user => ldf(user.profile.dateOfBirth)}
                        filter filterElement={dateTimeRangeFilterTemplate}
                        showFilterMenu={true}
                        showFilterMatchModes={false}
                        showFilterOperator={false}
                        showFilterMenuOptions={false}
        />
    },
    {
        field: "email",
        header: "Электронная почта",
        hidden: false,
        column: <Column key="email" field="email" sortable
                        body={user => <>{user.email}</>}
                        header="Электронная почта"
                        filter filterElement={textFilterTemplate}
        />
    },
    {
        field: "mobilePhone",
        header: "Мобильный телефон",
        hidden: false,
        column: <Column key="mobilePhone" field="mobilePhone" sortable
                        body={user => <>{user.mobilePhone ? formatPhone(user.mobilePhone.number) : ''}</>}
                        header="Мобильный телефон"
                        filter filterElement={textFilterTemplate}
        />
    },
    {
        field: "status",
        header: "Статус",
        hidden: false,
        column: <Column key="status" field="status" sortable
                        header="Статус"
                        body={(user: User) => <UserStatusOutput status={user.status}/>}
                        filter filterElement={UserStatusFilter}
        />
    },
    {
        field: "profile.therapist",
        header: "Врач",
        hidden: false,
        column: <Column key="therapist" field="profile.therapist" sortable
                        header="Врач"
                        body={therapistTemplate}
                        sortField="profile.therapist.id"
                        filter filterElement={TherapistFilter}
        />
    },
    {
        field: "profile.insurancePeriodStartDate",
        header: "Дата начала периода страхования",
        hidden: false,
        column: <Column key="profile.insurancePeriodStartDate" field="profile.insurancePeriodStartDate" sortable
                        header="Дата начала периода страхования"
                        dataType="date" body={(user) => user.profile.insurancePeriodStartDate}
                        filter filterElement={dateTimeRangeFilterTemplate}
                        showFilterMenu={true}
                        showFilterMatchModes={false}
                        showFilterOperator={false}
                        showFilterMenuOptions={false}
        />
    },
    {
        field: "profile.insurancePeriodEndDate",
        header: "Дата окончания периода страхования",
        hidden: false,
        column: <Column key="profile.insurancePeriodEndDate" field="profile.insurancePeriodEndDate" sortable
                        header="Дата окончания периода страхования"
                        dataType="date" body={(user) => user.profile.insurancePeriodEndDate}
                        filter filterElement={dateTimeRangeFilterTemplate}
                        showFilterMenu={true}
                        showFilterMatchModes={false}
                        showFilterOperator={false}
                        showFilterMenuOptions={false}
        />
    },
    {
        field: "profile.insurancePolicyNumber",
        header: "Номер страхового полиса",
        hidden: false,
        column: <Column key="profile.insurancePolicyNumber" field="profile.insurancePolicyNumber"
                        header="Номер страхового полиса"
                        dataType="text"
                        body={user => user.profile.insurancePolicyNumber}
                        sortable sortField="profile.insurancePolicyNumber"
                        filter filterElement={textFilterTemplate}
        />
    },
    {
        field: "profile.insuranceProgram.id",
        header: "Программа страхования",
        hidden: false,
        column: <Column key="profile.insuranceProgram.id" field="profile.insuranceProgram.id"
                        header="Программа страхования"
                        body={insuranceProgramTemplate}
                        sortable sortField="profile.insuranceProgram.id"
                        filter filterElement={InsuranceProgramFilter}
        />
    },
    /*
    {
        field: "profile.serviceProgram.id",
        header: "План обслуживания",
        hidden: false,
        column: <Column key="profile.serviceProgram.id" field="profile.serviceProgram.id"
                        header="План обслуживания"
                        body={serviceProgramTemplate}
                        sortable sortField="profile.serviceProgram.id"
                        filter filterElement={ServiceProgramFilter}
        />
    },
     */
    {
        field: "roles",
        header: "Роли пользователя",
        hidden: false,
        column: <Column key="roles" field="roles"
                        header="Роли пользователя"
                        body={roleTemplate}
                        filter filterElement={RoleFilter}
                        showFilterMenu={true}
                        showFilterMatchModes={false}
                        showFilterOperator={false}
                        showFilterMenuOptions={false}
        />
    },
    {
        field: "profile.organization.id",
        header: "Организация",
        hidden: false,
        column: <Column key="profile.organization.id" field="profile.organization.id"
                        header="Организация"
                        body={organizationTemplate}
                        sortable sortField="profile.organization.id"
                        filter filterElement={OrganizationFilter}
        />
    },
];